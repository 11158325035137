#gallery.box-main {
  padding: 0 15px;
  .box-g1{
    position: relative;
    .box-gallery-dropdown {
      margin-bottom: 2rem;
    }
  }
  .box-gallery-content {
    display: grid;
    grid-template-areas:
      "box-images"
      "box-paggination";
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    width: 100%;
    min-height: 60vh;
    border: 1px solid #e1e1e1;
    border-radius: 16px;
    background-color: #fff;
    margin-bottom: 2rem;
    padding-bottom: 1.5rem;
    .box-content-images {
      grid-area: box-images;
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 1rem;
      row-gap: 2.5rem;
      justify-items: center;
      margin-bottom: 2rem;
      padding: 1.5rem;
      @media (min-width: $breakpoint-md){
        grid-template-columns: repeat(auto-fill, minmax(32%, 1fr));
      }
      &>div {
        position: relative;
        aspect-ratio: 1 / 1;
        padding-top: 65%;
        justify-self: center;
        border: 0.5px solid $greyscale100;
        border-radius: 4px;
        @media (min-width: $breakpoint-md){
          // width: 250px;
        }
        &:hover {
          cursor: pointer;
        }
        &[data-layout-id=""]:hover {
          cursor: default;
        }
        i {
          position: absolute;
          left: 50%;
          top: 50%;
          translate: -50% -50%;
        }
        img {
          position: absolute;
          top: 0;
          left: unset0;
          max-width: 100%;
          height: 100%;
          width: 100%;
          object-fit: contain;
          font-family: $font-family-sans-serif;
          color: $greyscale400;
          font-weight: 700;
          font-size: 27px ;
          text-align: center;
          vertical-align: middle;
          border-radius: 4px;
          @media (min-width: $breakpoint-md){
            // width: 250px;
          }
        }
        p {
          position: absolute;
          left: 50%;
          bottom: 0;
          translate: -50% 100%;
          margin: 0;
          padding: 0;
          font-family: $font-family-sans-serif;
          font-size: 20px;
          color: $greyscale300;
        }
      }
      &>div#with-link {
        &:hover {
          cursor: pointer;
        }
      }
    }
    .box-content-pagination{
      grid-area: box-paggination;
      .box-pagination-button {
        display: flex;
        justify-content: center;
        align-items: center;
        &>div {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30px;
          height: 30px;
          border-radius: 15px;
          font-family: $font-family-sans-serif;
          font-size: 14px;
          line-height: 1.36;
          font-weight: 700;
          color: $greyscale300;
          &:first-child {
            background-color: $primary200;
            color: #fff;
            font-family: inherit;
            font-size: 17px;
            font-weight: 500;
            cursor: pointer;
            margin-right: 1rem;
            user-select: none;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
          }
          &:last-child {
            background-color: $primary200;
            color: #fff;
            font-family: inherit;
            font-size: 17px;
            font-weight: 500;
            cursor: pointer;
            margin-left: 1rem;
            user-select: none;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
          }
        }
      }
    }
  }
}
