@import "variables";

.auth-bg-color {
  background-color: $primary300;
  @media (min-width: $breakpoint-md){
    background-color:none;
    background: linear-gradient(90deg, #fff 0%, #fff 59%, #007964 59%,  #007964 100%);
  }
}

.auth-card-wrapper {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.auth-bg-image {
  @media (max-width: $breakpoint-md){
      min-height: 425px;
    }

  position: relative;

  img {
    &.bg-people {
      position: absolute;
      display: block;
      left: 50%;
      top: 37%;
      transform: translate(-50%, -20%);
      width: 79%;
      height: auto;
      @media (max-width: $breakpoint-md){
        top: 28%;
        max-width: 70vw;
      } 
    }
    &.bg-iconos {
      position: absolute;
      display: block;
      left: 50%;
      top: 37%;
      transform: translate(-50%, -58%);
      width: 86%;
      height: auto;
      @media (max-width: $breakpoint-md){
        top: 30%;
        max-width: 75vw;
      } 
    }

    @media (max-width: 1700px){
      top: 205px;
      right: 47px;
    }

    @media (max-width: 1400px){
      top: 220px;
      right: 40px;
    }

    @media (max-width: $breakpoint-xl){
      top: 270px;
      right: 30px;
    }

    @media (max-width: $breakpoint-xl){
      top: 310px;
      right: 20px;
    }

    @media (max-width: 767px){
      position: relative;
      top: 10px;
      left: 0;
      margin: 0 auto;
    }
  }
}

.auth-logo-rounded {
  align-self: end;
  padding: 24px;
  position: fixed;
  bottom: 0;
  right: 0;
  @media (min-width: $breakpoint-md){
    bottom: 0;
    right: 0;
  }
  img {
    max-width: 205px;

    @media (max-width: $breakpoint-xl){
      max-width: 130px;
    }

    @media (max-width: 767px){
      max-width: 105px;
      padding: 24px 0 0 0 ;
    }
  }
}

.centered {
  margin: 0 auto;
}

.auth-text-divider {
  display: flex;
  align-items: center;
  font-family: $font-family-sans-serif;
  font-weight: 700;
  font-size: 14px;
  font-style: normal;
  color: $greyscale200;
  --text-divider-gap: 1rem;

  &::before, &::after {
    content: '';
    height: 1px;
    background-color: $greyscale200;
    flex-grow: 1;
  }

  &::before {
    margin-right: var(--text-divider-gap);
  }

  &::after {
    margin-left: var(--text-divider-gap);
  }
}

.auth-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  min-height: 700px;
  @media (min-width: $breakpoint-md){
    min-height: 945px;
    background-color: none;
    background-clip: none;
    border: none;
  }
  border-radius: 0.25rem;
  margin: 0;
  
  .auth-content {
    padding: 0 20px 0 25px;
  }
}

.auth-logo {
  justify-content: center;
  margin: 30px 0 20px 0;
  display: flex;
  img {
    width: 250px;
  }
  
  &--register {
    margin: 45px 0 45px 0;
  }

  @media (min-width: $breakpoint-md){
    margin: 190px 0 90px 0;

    img {
      width: 300px;
    }
    &--register {
      margin: 90px 0 90px 0;
    }
  }
  

}

.sign-up-link-section {
  font-family: $font-family-sans-serif;
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  
  span {
    color: $greyscale200;
  }
  
  a {
    cursor: pointer;
    color: $secondary;
    transition: 0.2s;

    &:hover {
      color: darken($secondary, 10%);
      text-decoration: none;
      transition: 0.2s;
    }
  }
}

.logout-form {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
