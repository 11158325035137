@import "variables";

.input-control {
  input {
    height: calc(2.5em + 0.75rem + 2px);
    border-radius: 1rem;
    font-family: $font-family-sans-serif;
    font-weight: 500;
    font-size: 16px;
    font-style: normal;
    border: 1px solid $greyscale100;
    
    &:focus {
      border: 1px solid $greyscale200;
      box-shadow: none;
    }
  }
}

#remember {
  accent-color: $secondary;
  width: 1.2rem;
  height: 1.2rem;
  margin: 2px 0 0 -20px;
}

.input-label {
  font-family: $font-family-sans-serif;
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  color: $greyscale300;
}

.input-label-with-link {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;

  a {
    cursor: pointer;
    color: $secondary;
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    transition: 0.2s;
    
    &:hover {
      color: darken($secondary, 10%);
      text-decoration: none;
      transition: 0.2s;
    }
  }
}

.icon-class {
  position: relative;
  
  i {
    position: absolute;
    top: 13px;
    font-size: 22px;
    right: 15px;
    color: $greyscale200;
    cursor: pointer;
    transition: 0.2s;
    
    &:hover {
      transition: 0.2s;
      color: $greyscale300;
    }
  }
  
  input {
    padding: 0.375rem 3.2rem 0.375rem 0.75rem;
  }
}

.form-control.input-invalid {
  border-color: #e55353;
  &:focus{
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }
}