.publish-to-platform {
    .instacart-fields {
        label {
            word-break: break-all;
        }

        .instacart-products {
            max-height: 500px;
            overflow: auto;
            padding: 0 10px;
        }
    }

    #additional_product_ids {
        max-height: 500px;
        overflow: auto;
    }

    #additional_product_ids .sticky-header {
        position: sticky;
        top: 0;
        background: white;
        z-index: 10;
    }
}
