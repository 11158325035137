.dataTable {
  th {
    color: $primary300 !important;
  }
}

.dataTables_paginate {
  .paginate_button {
    color: $greyscale300;
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    border: none !important;
    box-shadow: none !important;

    &:hover {
      color: $greyscale300 !important;
      background: transparent;
    }

    &.current {
      color: white !important;
      background: $primary200;
      padding: 9px 12px 9px 11px;
      border-radius: 14px;
      border: none;

      &:hover {
        color: white !important;
        background: $primary200;
      }
    }

    &.previous,
    &.next {
      position: relative;
      border: none;

      &.disabled {
        opacity: 0;
      }

      &:hover {
        border: none;
        background: transparent;
      }
    }
  }

  .ellipsis {
    padding: 0 !important;
  }
}
