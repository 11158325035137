#app.c-body .c-main .container-fluid .box-banner.kroger,
#app.c-body .c-main.h .container-fluid .box-banner.kroger {
  .box-form #adForm {
    &>div:nth-last-child(3) {
      &>div:first-child {
        display: flex; 
        align-items: flex-end;
        margin-bottom: 1rem;
        input {
          margin-right: 0.4rem;
        }
        label {
          margin-bottom: 0;
        }
      }
    }
    &>div:nth-last-child(2)>div>div {
      display: flex; 
      align-items: flex-end;
      margin-bottom: 1rem;
      input {
        position: static;
        margin-right: 0.4rem;
        padding: 0;
        margin-bottom: 0;
      }
      label {
        line-height: unset;
      }
    }
  }
  .form-row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(125px, .333fr));
    margin-top: 0;
    &>div {
      min-width: 100%;
    }
  }
  label {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-height: 1.2em;
    max-width: 100%;
  }
  .box-button {
    padding-top: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(125px, .333fr));
    margin-top: 0;
    button {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-height: 38px;
      max-width: 100%;
      &[bg="fill"] {
        background-color: $primary200 !important;
        color: white !important;
        border: none !important;
      };
    }
  }
}

#app.c-body .c-main.v .container-fluid .box-banner.kroger {
  .box-form #adForm {
    &>div:nth-child(3) {
      &>div {
        display: block;
      }
    }
  }
  .box-button {
    padding-top: 0;
    grid-template-columns: repeat(auto-fit, minmax(125px, .333fr));
  }
}
#app.c-body .c-main.f .container-fluid .box-banner.kroger { 

}