@import "../variables";

#app.c-body .c-main .container-fluid .box-banner.box,
#app.c-body .c-main.h .container-fluid .box-banner.box {
  #toggleOptionalText {
    color: $greyscale400;
    text-decoration: none
  }
  
  .form-row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
    margin-top: 0;
    &>div {
      min-width: 100%;
    }
  }
  label {
    //text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    //max-height: 1.2em;
    //max-width: 100%;
  }
  .box-button {
    padding-top: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(125px, .3fr));
    margin-top: 0;
    button {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-height: 38px;
      max-width: 100%;
      &[bg="fill"] {
        background-color: $primary200 !important;
        color: white !important;
        border: none !important;
      };
    }
  }

}

#app.c-body .c-main.v .container-fluid .box-banner.box {
  .form-row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    margin-top: 0;
    &>div {
      min-width: 100%;
    }
  }
  
  .box-button {
    padding-top: 0;
    grid-template-columns: repeat(auto-fit, minmax(125px, .3fr));
  }
}
