@import "variables";

//used in js/frontend/banner-view.js
.bg-white {
  background-color: #ffffff !important;
}

#toggle-view{
  align-self: center;
  border-radius: 15px;
  border: 1px solid #e1e1e1;
}

#app.c-body .c-main.loading .container-fluid .box-banner {
  display: grid;
  grid-template-areas: "banner-spinner";
  grid-template-columns:auto;
  grid-template-rows: auto;
  padding-left: 0;
  padding-right: 0;
  height: calc(100vh - 68px);
  width: calc(100% + 60px);
  margin-top: -32px;
  margin-left: -30px;
  &::after {
    content: " ";
    grid-area: banner-spinner;
    justify-self: center;
    align-self: center;
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 3px solid $primary100;
    border-color: $primary100 $primary100 $primary100 transparent;
    animation: banner-spinner .8s linear infinite;
  }
  @keyframes banner-spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  &>div {
    display: none;
  }
}

#app.c-body .c-main .container-fluid .box-banner,
#app.c-body .c-main.h .container-fluid .box-banner {
  &::after {
    display: none;
  }
  &.denie-selection {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  display: grid;
  grid-template-areas:
  "box-toggle-view"
  "preview"
  "form";
  padding-left: 0;
  padding-right: 0;
  height: calc(100vh - 68px);
  width: calc(100% + 60px);
  margin-top: -32px;
  margin-left: -30px;
  .box-toggle-view {
    grid-area: box-toggle-view;
    align-content: center;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    height: auto;
    order: 5; 
    #toggle-view{
      grid-column: -2 / span 1;
      justify-self: start;
      border-radius: 15px;
      border: 1px solid #e1e1e1;
      width: auto;
    }
  }

  .box-form {
    display: block;
    position: relative;
    z-index: 20;
    grid-area: form;
    padding: 12px 5px;
    background-color: $greyscale50;
    align-self: end;
    .handle-resize {
      position: absolute;
      top: -26px;
      left: 50%;
      transform: translateX(-50%);
      box-sizing: border-box;
      width: 43px;
      height: 21px;
      border: 1px solid #E1E1E1;
      border-radius: 12px;
      background-image: url('/img/icons/resize-arrow-v.svg');
      background-repeat: no-repeat;
      background-position: center;
      &:hover{
        cursor: row-resize;
      }
    }
  }
  .box-preview {
    grid-area: preview;
    position: relative;
    display: flex;
    justify-content: center;
    padding: 30px;
    justify-self: center;
    height: fit-content;
    border: 1px solid $greyscale300;
    border-radius: 12px;
    #preview-popup {
        &>div:nth-child(2) {
        border: 1px solid $greyscale100;
        border-radius: 4px;
      }
      .canvas-container {
        overflow: hidden;
      }
    }
  }
  &>#preview-popup {
    position: fixed;
  }

  form#adForm {
    padding: 0 20px;
    font-family: $font-family-sans-serif;
    display: grid;
    grid-template-columns: 15% auto;
    row-gap: 5px;
    height: 300px;
    width: auto;
    overflow-y: scroll;
    align-content: start;
    @media (max-width: $breakpoint-md){
      grid-template-columns: 1fr;
    }
  
    & > div:first-child {
      grid-row: span 3;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      @media (max-width: $breakpoint-md){
        flex-direction: row;
        grid-row: auto;
      }
    }
  
    & > div:nth-child(n + 5) {
      grid-column: span 2;
      @media (max-width: $breakpoint-md){
        grid-column: auto;
      }
    }
    button.btn.btn-primary.select-bkimg {
      color: transparent;
      background-image: url('/img/icons/banner-file.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-color: #e9ecef;
      border: 1px solid #b1b1b1;
      border-radius: 15px;
      width: 80%;
    }
    .selected-image {
      right: 4%;
      top: unset;
      bottom: 4%;
      &:hover > div.remove-selecter-image {
          display: flex;
      }
      div.remove-selecter-image {
          display: none;
          justify-content: center;
          align-items: center;
          width: 17px;
          height: 17px;
          position: absolute;
          top: -7px;
          right: -7px;
          background-color: #b1b1b1;
          border-radius: 50%;
          cursor: pointer;
          &::before {
            content: '\f00d';
            color: #fff;
            font-family: "Font Awesome 5 Free";
            font-size: 11px;
            font-weight: 700;
            margin-top: 1px;
          }
          &:hover {
            background-color: #6c757d;
          }
          &[data-value=""]{
            display: none;
          }
      }

      .image-preview-item {
        display: flex;
        img {
          width: 100%;
          object-fit: cover;
        }
        &:hover > div.remove-selecter-image {
          display: flex;
          z-index: 1;
        }
      }
      
    }

    div.file-caption .input-group {
      input.file-caption-name {
        min-width: 50px;
        border-left: 1px solid #b1b1b1 !important;
        border-top: 1px solid #b1b1b1 !important;
        border-bottom: 1px solid #b1b1b1 !important;
        border-right: none !important;
        border-bottom-left-radius: 15px !important;
        border-top-left-radius: 15px !important;
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important;
        background-color: transparent;
      }
      button.fileinput-cancel-button {
        border-top: 1px solid #b1b1b1 !important;
        border-bottom: 1px solid #b1b1b1 !important;
        background-color: transparent;
      }
      div.btn-file{
        border-right: 1px solid #b1b1b1 !important;
        border-top: 1px solid #b1b1b1 !important;
        border-bottom: 1px solid #b1b1b1 !important;
        border-left: none !important;
        border-bottom-right-radius: 15px !important;
        border-top-right-radius: 15px !important;
        background-color: transparent;
        color: #6c757d;
      }
    }
  
    .form-group, .form-row-group {
      label {
        color: $greyscale400;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        //line-height: 1.375;
        //margin-bottom: 10px;
      }
    }
  
    .form-control {
      border-radius: 15px;
      border: 1px solid $greyscale200;
      &::placeholder {
        color: $greyscale200;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.333;
      }

      &:focus {
        box-shadow: none;
        border: 1px solid $greyscale200;
      }
    }
    button.btn.btn-primary {
      background-color: transparent;
      color: $primary300;
      border: 1px solid $primary300;
      border-radius: 6px;
      max-height: 38px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      transition: 0.2s;

      &:hover {
        transition: 0.2s;
        border-color: $primary300!important;
      }

      &:focus {
        box-shadow: none!important;
        border: 1px solid $primary300!important;
        background-color: transparent!important;
      }

      &:active {
        box-shadow: none!important;
        border: 1px solid $primary300!important;
        background-color: transparent!important;
      }

      &:disabled {
        box-shadow: none!important;
        border: 1px solid $greyscale400!important;
        color: $greyscale400!important;
        background-color: transparent!important;
        cursor: default!important;
      }
    }
    
    #download-ads, #publish-team-ads {
      background-color: $primary200 !important;
      color: white !important;
      border: none !important;
      
      &:hover {
        transition: 0.2s;
        border-color: $primary300!important;
      }

      &:focus {
        box-shadow: none!important;
        border: 1px solid $primary300!important;
        background-color: $primary300!important;
      }

      &:active {
        box-shadow: none!important;
        border: 1px solid $primary300!important;
        background-color: $primary300!important;
      }

      &:disabled {
        box-shadow: none!important;
        border: 1px solid $greyscale400!important;
        color: $greyscale400!important;
        background-color: transparent!important;
        cursor: default!important;
      }
    }
    
    .generate-alert {
      line-height: 40px;
    }
    
    input[type="number"] {
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        -moz-appearance: textfield;
        appearance: textfield;
        margin: 0;
      }
      -moz-appearance: textfield;
      appearance: textfield;
      margin: 0;
    }
    input[type="checkbox"] {
      padding: 1px;
    }
    input[type="color"] {
      max-width: 100% !important;
      -webkit-appearance: none;
      border: 1px solid #e1e1e1;
      border-radius: 4px;
      width: 40px;
      height: 38px;
    }
    input[type="color"]::-webkit-color-swatch-wrapper {
      padding: 1px !important;
    }
    input[type="color"]::-webkit-color-swatch {
      border: 1px solid #e1e1e1;
      border-radius: 4px;
    }
  }
  div.customer-wrapper {
    position: relative;
    width: 124px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 19px;
    & > p {
      margin-bottom: 10px;
    }
    & .selected-customer {
      width: 100px;
      border: 0.5px solid #e1e1e1;
      border-radius: 9px;
      box-shadow: 0px 4px 4px #e1e1e1;
    }
    & .customers.slide-popup {
      min-width: 70vw;
    }
  }
  div.template-wrapper {
    position: relative;
    width: 124px;
    display: flex;
    flex-direction: column;
    align-items: center;
    & > p {
      margin-bottom: 10px;
    }
    & .selected-template {
      width: 100px;
      overflow: hidden;
      border: 0.5px solid #e1e1e1 !important;
      border-radius: 9px;
      box-shadow: 0px 4px 4px #e1e1e1 !important;
    }
    & .templates.slide-popup {
      min-width: 70vw;
    }
  }
}

#app.c-body .c-main.v .container-fluid .box-banner{
  &::after {
    display: none;
  }

  grid-template-columns: auto max-content;
  grid-template-rows: 70px auto;
  grid-template-areas:
  "box-toggle-view form"
  "preview form";

  .box-form {
    align-self: auto;
    height: calc(100vh - 68px);
    background-color: transparent;
    .handle-resize {
      top: 50%;
      left: -26px;
      transform: translateX(-50%) rotate(90deg);
      box-sizing: border-box;
      width: 43px;
      height: 21px;
      &:hover{
        cursor: col-resize;
      }
    }
  }

  form#adForm {
      width: 420px;
      height: 100%;
      grid-template-columns: 1fr;
      grid-auto-rows: auto;
      overflow-y: scroll;
      scrollbar-color: $greyscale200 transparent;
      scrollbar-width: thin;
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background: $greyscale200;
        border-radius: 10px;
      }
      .customers.slide-popup {
        position: fixed;
        width: 70vw;
        top: 105px;
      }
      .templates.slide-popup {
        position: fixed;
        width: 70vw;
        top: 105px;
      }
  
    & > div:first-child {
        flex-direction: row;
        align-items: flex-start;
        grid-row: auto;      
    }

    & > div:nth-child(n + 5) {
        grid-column: auto;
    }
    button.btn.btn-primary {
      margin-bottom: 1rem;
    }
    div.position-relative {
      position: static !important;
      .customer-wrapper {
        position: static;
        margin-bottom: 0;
      }
      .template-wrapper{
        position: static;
      }
      .customer-wrapper,
      .template-wrapper {
        &>p {
          opacity: 1;
          transition: all 250ms linear;
        }
      }
      .customer-wrapper.slave,
      .template-wrapper.slave {
        &>p {
          opacity: 0;
        }
      }
      .customer-wrapper.master,
      .template-wrapper.master {
        &>p {
          opacity: 1;
          font-weight: 700;
        }
      }
      .customer-wrapper .customers.slide-popup,
      .template-wrapper .templates.slide-popup {
        position: absolute;
        min-width: 55vw;
        width: 55vw;
        top: 20px;
        left: unset;
        right: 20px;
        &::before {
          display: none;
          left: 95%;
        }
      }
    }
    #file_ids_dropdown{
      right: 0;
    }
    div.file-caption .input-group {
      input.file-caption-name {
        min-width: 50px;
        border-left: 1px solid #e1e1e1 !important;
        border-top: 1px solid #e1e1e1 !important;
        border-bottom: 1px solid #e1e1e1 !important;
        border-right: none !important;
        border-bottom-left-radius: 15px !important;
        border-top-left-radius: 15px !important;
        background-color: transparent;
      }
      button.fileinput-cancel-button {
        border-top: 1px solid #e1e1e1 !important;
        border-bottom: 1px solid #e1e1e1 !important;
        background-color: transparent;
      }
      div.btn-file{
        border-right: 1px solid #e1e1e1 !important;
        border-top: 1px solid #e1e1e1 !important;
        border-bottom: 1px solid #e1e1e1 !important;
        border-left: none !important;
        border-bottom-right-radius: 15px !important;
        border-top-right-radius: 15px !important;
        background-color: transparent;
        color: #6c757d;
      }
    }
    input[type="color"] {
      max-width: 100% !important;
      -webkit-appearance: none;
      border: 1px solid #e1e1e1;
      border-radius: 4px;
      width: 40px;
      height: 38px;
    }
    input[type="color"]::-webkit-color-swatch-wrapper {
      padding: 1px !important;
    }
    input[type="color"]::-webkit-color-swatch {
      border: 1px solid #e1e1e1;
      border-radius: 4px;
    }
    .selected-image {
      bottom: 21%;
    }
  }
  .box-preview {
    align-self: center;
  }
}

#app.c-body .c-main.f {
  background-color: $greyscale50;
}
#app.c-body .c-main.f .container-fluid .box-banner {
  grid-template-areas: "form";
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  height: auto;
  .box-form {
    z-index: 0;
  }
  #adForm {
    display: block;
    height: auto;
    width: 100%;
    overflow-y: unset;

    & > div:first-child {
      grid-row: span 3;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 16px;
      @media (min-width: $breakpoint-md){
        flex-direction: row;
      }
    }
    div.customer-wrapper {
      width: auto;
      margin-bottom: 0px;
      margin-right: 40px;
    }
    div.template-wrapper {
      width: auto;
      & .selected-template {
        width: auto;
        overflow: auto;
      }
    }
  }
  #preview-popup{
    top: 70px;
    right: 5px;
    border: 0.5625px solid $greyscale200;
    border-radius: 6px;
    box-shadow: rgba(208, 208, 208, 0.45) 0px 4px 20px;
    #drag-handler {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      background-color: $greyscale100;
      cursor: move;
    }
    #footer {
      border-top: 0.5625px solid $greyscale100;
      border-bottom: none;
      border-right: none;
      border-left: none;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      padding: 5px 0;
      &>div:first-child{
        display: flex;
        align-items: flex-end;
        &>input {
          margin-right: 5px;
        }
        &>label {
          margin-bottom: 0;
        }

      }
    }
    #canvas {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
}

@import "./banner-create/box";
@import "./banner-create/new-template";
@import "./banner-create/sam";
@import "./banner-create/kroger";
@import "./banner-create/welmart";
@import "./banner-create/superama";
@import "./banner-create/amazon-fresh";
@import "./banner-create/instagram";