.c-app.modal-open #selectBkImgModal .modal-dialog .modal-content {

    box-sizing: content-box;
    background-color: #F6F6F6;
    padding: 23px 50px 27px;
    border-radius: 20px;

    .modal-header {
      padding: 0;
      margin-bottom: 29px;
      border-bottom: none;

      #exampleModalLabel {
        font-family: $font-family-sans-serif;
        font-size: 24px;
        font-weight: 800;
        line-height: 1.375;
        color: $greyscale400;
      }
      .close {
        padding: 0;
        margin: 0;
      }
    }


    .modal-body {
      width: auto;
      padding: 0;
      nav {
        display: grid;
        grid-template-columns: 2fr 1.5fr;
        grid-template-rows: 1fr;
        margin-bottom: 25px;
        #nav-tab {
          display: flex;
          border-bottom: 0;
          .nav-link {
            border: none;
            border-radius: 12px;
            background-color: transparent;
            margin-right: 1rem;
            font-family: $font-family-sans-serif;
            font-size: 14px;
            font-weight: 700;
            line-height: 1.36;
            color: $greyscale300;
          }
          .nav-link.active {
            background-color: $primary300;
            color: #FFFFFF;
          }
        }
        &>div:nth-child(2) {
          position: static !important;
        }
        &>div:last-child {
          position: static !important;
          input {
            //border-radius: 12px !important;
            //border: 1px solid $greyscale200 !important;
          }

          .select2-selection__choice{
            padding-left: 4px!important;
            border-radius: 15px!important;
            color: white!important;
            background-color: #14A2B8;
            border: none!important;

            .select2-selection__choice__remove{
              color: white!important;
              left: auto;
              right: 0;
            }

            .select2-selection__choice__display {
              padding-left: unset !important;
              padding-right: 12px;
            }
          }

          input::placeholder {
            color: $greyscale200;
            font-family: $font-family-sans-serif;
            font-size: 10.6px;
            font-weight: 500;
            line-height: 1.32;
          }
        }
      }
      #nav-tabContent>div>div:first-child,
      #nav-tabContent .stock-image-grid {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: auto;
          justify-items: center;
          grid-row-gap: 2rem;
          margin-bottom: 19px;
          &>div {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: auto;
            height: auto;
            margin: 0;
            input.select-check {
              display: none;
            }
            img {
              width: 150px;
              height: 144px;
              border: 1px solid $greyscale100;
              border-radius: 10px;
              box-shadow: 0px 4px 4px $greyscale100;
              margin-bottom: 12px;
              &:hover{
                cursor: default;
              }
            }
            a {
              max-width: 150px;
              font-family: $font-family-sans-serif;
              font-size: 14px;
              font-weight: 500;
              line-height: 1.375;
              color: black;
            }
            a.item-filename {
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translate(-50%, 100%);;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              z-index: 4;
              &:hover {
                bottom: 5px;
                background-color: white;
                padding: 5px;
                border-radius: 4px;
                box-shadow:  0px 4px 4px $greyscale100;
                white-space: normal;
              }
            }
            div.select-same-image-container {
              display: inline-flex;
              justify-content: space-around;
              min-width: 148px;
              line-height: 1;
              font-weight: 600;
              font-size: 16px;
              position: absolute;
              bottom: 12px;
              color:  $greyscale50;
              backdrop-filter: blur(6px);
              background-color: rgba(0, 0, 21, 0.3);
              border-radius: 0 0 10px 10px;
              left: 1px;
              .mines {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 40px;
                min-width: 40px;

              }
              .plus {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 40px;
                min-width: 40px;
              }
              .mines:hover,.plus:hover {
                color: $secondary;
              }
              .counter {
                min-width: 27px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
            div.overlay {
              height: fit-content;
              left: unset;
              transform: scale(.5);
              transform-origin: top right;
              backdrop-filter: blur(6px);
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              overflow: hidden;
              a {
                background-color: rgba(0, 0, 21, 0.3);
                i::before{
                  display: inline-block;
                  margin: 14px 3px;
                  font-size: 30px;
                  color: $greyscale50
                }
              }
            }
            div.overlay {
              a:hover {
                i::before{
                  font-weight: 700;
                  color: $secondary;
                }
              }
            }
          }
          &>div.selected {
            img {
              border: 2px solid $primary200;
              box-shadow: 0px 4px 4px $greyscale100;
            }
          }
          &>div:last-child {
            width: 100%;
            grid-column: 1/ 4;
            align-items: center;
            justify-content: center;
            margin: 0 !important;
            padding: 0 !important;
            &>div {
              width: min-content;
              margin: 0 !important;
              padding: 0 !important;
              nav {
                margin-bottom: 0;
                ul {
                  margin-bottom: 0;
                  li {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 35px;
                    width: 29px;
                    border: none;
                    border-radius: 12px;
                    margin-right: 5px;
                    a {
                      background-color: transparent;
                      border: none;
                      color: $greyscale300;
                    }
                  }
                  li.active {
                    background-color: $primary200;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    a {
                      color: white;
                    }
                  }
                }
              }
            }
          }
      }

      #nav-tabContent .back-to-folder-link{
        display: flex!important;
      }

      .show-favorite-files {
        .fa {
          font-weight: 400;
        }

        &.active {
          .fa {
            font-weight: 900;
          }
        }
      }

      #nav-tabContent .files-image-grid{
        display: grid;
        grid-template-rows: auto;
        justify-items: center;
        grid-row-gap: 2rem;
        margin-bottom: 19px;
        grid-template-columns: repeat(6, 1fr)!important;
        min-height: 437px;

        .grid-item {
          cursor: pointer;

          &:hover {
            .favorite-file {
              display: block;
            }
          }

          .favorite-file {
            display: none;
            position: absolute;
            right: 14px;

            .fa {
              font-weight: 400;
            }

            &.is-favorite {
              display: block;

              .fa {
                font-weight: 900;
              }
            }
          }
        }

        &>div {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: auto;
          height: fit-content;
          margin: 0;
          input.select-check {
            display: none;
          }
          img {
            width: 115px!important;
            height: 100px!important;
            border: 1px solid $greyscale100;
            border-radius: 10px;
            box-shadow: 0px 4px 4px $greyscale100;
            margin-bottom: 12px;
            &:hover{
              cursor: default;
            }
          }
          a {
            max-width: 150px;
            font-family: $font-family-sans-serif;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.375;
            color: black;
          }
          a.item-filename {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 100%);;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            z-index: 4;
            &:hover {
              bottom: 5px;
              background-color: white;
              padding: 5px;
              border-radius: 4px;
              box-shadow:  0px 4px 4px $greyscale100;
              white-space: normal;
            }
          }
          div.select-same-image-container {
            display: inline-flex;
            justify-content: space-around;
            min-width: 148px;
            line-height: 1;
            font-weight: 600;
            font-size: 16px;
            position: absolute;
            bottom: 12px;
            color:  $greyscale50;
            backdrop-filter: blur(6px);
            background-color: rgba(0, 0, 21, 0.3);
            border-radius: 0 0 10px 10px;
            left: 1px;
            .mines {
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 40px;
              min-width: 40px;

            }
            .plus {
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 40px;
              min-width: 40px;
            }
            .mines:hover,.plus:hover {
              color: $secondary;
            }
            .counter {
              min-width: 27px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          div.overlay {
            height: fit-content;
            left: unset;
            transform: scale(.5);
            transform-origin: top right;
            backdrop-filter: blur(6px);
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            overflow: hidden;
            a {
              background-color: rgba(0, 0, 21, 0.3);
              i::before{
                display: inline-block;
                margin: 14px 3px;
                font-size: 30px;
                color: $greyscale50
              }
            }
          }
          div.overlay {
            a:hover {
              i::before{
                font-weight: 700;
                color: $secondary;
              }
            }
          }
        }
        &>div.selected {
          img {
            border: 2px solid $primary200;
            box-shadow: 0px 4px 4px $greyscale100;
          }
        }
        &>div:last-child {
          width: 100%;
          grid-column: 1/ 4;
          align-items: center;
          justify-content: center;
          margin-top: auto;
          padding: 0 !important;
          &>div {
            width: min-content;
            margin: 0 !important;
            padding: 0 !important;
            nav {
              margin-bottom: 0;
              ul {
                margin-bottom: 0;
                li {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 35px;
                  width: 29px;
                  border: none;
                  border-radius: 12px;
                  margin-right: 5px;
                  a {
                    background-color: transparent;
                    border: none;
                    color: $greyscale300;
                  }
                }
                li.active {
                  background-color: $primary200;
                  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                  a {
                    color: white;
                  }
                }
              }
            }
          }
        }

        svg{
          width: 115px!important;
          height: 100px!important;
        }
      }
    }

    .modal-footer {
      padding: 0;
      margin: 0;
      border-top: none;
      button {
        padding: 10.5px;
        border: 1px solid $primary200;
        border-radius: 5.89px;
        color: $primary200;
        background-color: transparent;
        font-family: $font-family-sans-serif;
        font-size: 11.74px;
        font-weight: 700;
        line-height: 1.36;
      }
      button#submit {
        padding: 10.5px;
        border-radius: 5.89px;
        font-family: $font-family-sans-serif;
        font-size: 11.74px;
        font-weight: 700;
        line-height: 1.36;
      }
      .active {
        background-color: $primary200;
        color: #FFFFFF;
      }
      .file-input {
        margin: 0;
        .input-group {
          div.file-caption.form-control {
            max-height: 38px;
            border-top: 1px solid $greyscale100;
            border-left: 1px solid $greyscale100;
            border-bottom: 1px solid $greyscale100;
            border-right: none;
            border-top-left-radius: 15px !important;
            border-bottom-left-radius: 15px !important;
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            background-color: #e9ecef;
            color: #495057;
            input.file-caption-name {
              max-height: unset;
              border: none;
              &::placeholder {
                color: $greyscale200;
                font-family: $font-family-sans-serif;
                font-size: 10.6px;
                font-weight: 500;
                line-height: 1.32;
              }
            }
          }
          input.file-caption-name {
            max-height: 38px;
            border-top: 1px solid $greyscale100;
            border-left: 1px solid $greyscale100;
            border-bottom: 1px solid $greyscale100;
            border-right: none;
            border-top-left-radius: 15px !important;
            border-bottom-left-radius: 15px !important;
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            &::placeholder {
              color: $greyscale200;
              font-family: $font-family-sans-serif;
              font-size: 10.6px;
              font-weight: 500;
              line-height: 1.32;
            }
          }
          button {
            max-height: 38px;
            border-top: 1px solid $greyscale100;
            border-bottom: 1px solid $greyscale100;
            border-left: none;
            border-right: none;
            border-radius: 0;
            color: $primary200;
          }
          div.btn.btn-primary.btn-file {
            max-height: 38px;
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
            border-bottom-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top: 1px solid $greyscale100;
            border-right: 1px solid $greyscale100;
            border-bottom: 1px solid $greyscale100;
            border-left: none;
            padding: 10.5px;
            background-color: $primary200;
            color: #FFFFFF;
            font-family: $font-family-sans-serif;
            font-size: 11.74px;
            font-weight: 700;
            line-height: 1.36;
          }
        }
      }

      input#update-bg-image-on-theme-change,
      input#scaleToTemplateSize {
          accent-color: $primary300;
      }
    }


}

#setFilenameCroppedImage .modal-dialog .modal-content,
#saveCroppedImage .modal-dialog .modal-content,
#setFilenameFadeImage .modal-dialog .modal-content {
    .modal-footer {
      padding: 0;
      margin: 0;
      border-top: none;
      button {
        padding: 10.5px;
        border: 1px solid $primary200;
        border-radius: 5.89px;
        color: $primary200;
        background-color: transparent;
        font-family: $font-family-sans-serif;
        font-size: 11.74px;
        font-weight: 700;
        line-height: 1.36;
      }
      button#submit {
        padding: 10.5px;
        border-radius: 5.89px;
        background-color: $primary200;
        color: #FFFFFF;
        font-family: $font-family-sans-serif;
        font-size: 11.74px;
        font-weight: 700;
        line-height: 1.36;
      }
    }
}

#full-size-image-dist .container-sidebar{
  z-index: 1;
  background-color: #e1e1e1;
  width: 100%;

  #btnPen, #btnClear, #btnPerspective {
    font-size: 20px;
  }
  a{
    cursor: pointer;
    margin: 5px;
  }
  a:active{
    color: #4a9d87 !important;
  }
}

#myCanvas{
  cursor: url(/img/icons/pencil.png)0 30, auto !important;
}

.perspective-canvas, .bounding-rectangle{
  position:absolute;
  left: 0;
  top: 60px;
}

.breadcrumbs-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
    margin-bottom: 16px;

    .back-to-folder-link {
        cursor: pointer;
        
        i {
            font-size: 16px;
        }
    }

    .folders {
        color: $greyscale300;
        
        .folder-link {
            color: $primary200;
            text-decoration: none;
            
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .show-favorite-files {
        color: $primary200;
        text-decoration: none;
        
        i {
            margin-right: 4px;
        }
        
        &.active {
            i {
                font-weight: 900;
            }
        }
    }
}
