.select2-custom {
  .select2-container {
    min-width: 150px;

    &--default {
      .select2-selection--multiple {
        background: none;

        .select2-selection__choice {
          border: none;
          border-radius: 10rem;
          background-color: #17a2b8;
          color: white;
          padding-right: 0.6em;
          padding-left: 0.6em;

          &__remove {
            color: white;
          }
        }
      }
    }
  }

  .select2-results__option--highlighted {
    background-color: #17a2b8 !important;
  }
}
