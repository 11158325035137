#filedrag
{
	font-weight: bold;
	text-align: center;
	padding: 1em 0;
	margin: 1em 0;
	color: #555;
	border: 2px dashed #555;
	border-radius: 7px;
    cursor: default;
    
    &.hover
    {
        color: #000;
        border-color: #000;
        border-style: solid;
        box-shadow: inset 0 3px 4px #888;
    }
}

.krajee-default.file-preview-frame .file-thumbnail-footer {
    height: auto;
}

.krajee-default .file-footer-caption {
    margin-bottom: 10px;
}

.krajee-default.file-preview-frame .kv-file-content {
    width: 160px;
    height: 100px;
}

.file-drop-zone-title {
    padding: 40px 10px;
    font-size: 16px;
}

.file-drop-zone {
    min-height: 150px;
}

.file-zoom-dialog .btn-next {
    right: 30px;
}

.file-zoom-dialog .btn-prev {
    left: -15px;
}

.col-product-layout {
    width: 150px;
}

.col-project-name {
    flex: 1;
}

.file-url-input-group {
    border-radius: 5px;
    border: 1px solid #ddd;
    padding: 16px !important;
    height: 192px;
}

.product-image {
    max-height: 400px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}