#app.c-body .c-main .container-fluid .box-banner.new-template,
#app.c-body .c-main.h .container-fluid .box-banner.new-template {
  .form-row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(125px, .3333fr));
    margin-top: 0;
    &>div {
      display: flex;
      flex-direction: column;
      min-width: 100%;
      label {
        overflow: clip;
        margin-bottom: 5px;
      }
      br {
        display: none;
      }
      select {
        border-radius: 15px;
        border: 1px solid #b1b1b1;
      }
      span.selection>span {
        border-radius: 15px;
        border: 1px solid #b1b1b1;
      }
      div.input-group {
        input[type="number"] {
          border-left: 1px solid #b1b1b1 !important;
          border-top: 1px solid #b1b1b1 !important;
          border-bottom: 1px solid #b1b1b1 !important;
          border-right: none !important;
          border-bottom-left-radius: 15px !important;
          border-top-left-radius: 15px !important;
          border-bottom-right-radius: 0px !important;
          border-top-right-radius: 0px !important;
        }
        
        input[type="number"].both_sided {
          border-left: none !important;
          border-top: 1px solid #b1b1b1 !important;
          border-bottom: 1px solid #b1b1b1 !important;
          border-right: none !important;
          border-bottom-left-radius: 0px !important;
          border-top-left-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
          border-top-right-radius: 0px !important;
        }
                
        div.input-group-append span {
          border-right: 1px solid #b1b1b1;
          border-top: 1px solid #b1b1b1;
          border-bottom: 1px solid #b1b1b1;
          border-left: none;
          border-bottom-right-radius: 15px;
          border-top-right-radius: 15px;
          color: #6c757d;
        }
        div.input-group-btn.input-group-append span {
          border: none;
        }
        div.input-group-prepend span {
          border-left: 1px solid #b1b1b1;
          border-top: 1px solid #b1b1b1;
          border-bottom: 1px solid #b1b1b1;
          border-right: none;
          border-bottom-left-radius: 15px;
          border-top-left-radius: 15px;
          color: #6c757d;
        }
        div.input-group-btn.input-group-prepend span {
          border: none;
        }
      }
      div.input-group.file-caption-main div.file-caption.form-control {
        border-left: 1px solid #b1b1b1 !important;
        border-top: 1px solid #b1b1b1 !important;
        border-bottom: 1px solid #b1b1b1 !important;
        border-right: none !important;
        border-bottom-left-radius: 15px !important;
        border-top-left-radius: 15px !important;
        border-bottom-right-radius: 0px !important;
        border-top-right-radius: 0px !important;
      }
      div.input-group.file-caption-main div.input-group-btn.input-group-append {
        .fileinput-cancel {
          border-top: 1px solid #b1b1b1 !important;
          border-bottom: 1px solid #b1b1b1 !important;
          background-color: transparent;
          max-height: 38px;
          padding: 0 14px;
          color: #6c757d;
        }
        .btn-file {
          border-right: 1px solid #b1b1b1 !important;
          border-top: 1px solid #b1b1b1 !important;
          border-bottom: 1px solid #b1b1b1 !important;
          border-left: none !important;
          border-bottom-right-radius: 15px !important;
          border-top-right-radius: 15px !important;
          background-color: transparent;
          color: #6c757d;
          max-height: 38px;
        }
      }
    }
  }
  .form-row.overloaded {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
    margin-top: 0;
    @media (min-width: $breakpoint-md){
      grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
    }
    @media (min-width: $breakpoint-lg){
      grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
    }
    &>div {
      min-width: 100%;
    }
    .form-row {
      display: flex;
    }
    .form-row.with-color div {
      min-width: auto;
      width: auto;
    }
  }
  label {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-height: 1.2em;
    max-width: 100%;
  }
  .box-button {
    padding-top: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(125px, .3fr));
    margin-top: 0;
    button {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-height: 38px;
      max-width: 100%;
      &[bg="fill"] {
        background-color: $primary200 !important;
        color: white !important;
        border: none !important;
      };
    }
  }
  input[type=text].form-control {
      border-radius: 15px !important;
      border: 1px solid #e1e1e1;
      &::placeholder {
        color: $greyscale200;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.333;
      }
    }
  div.form-group {
    &>input[type=color],
    &>input[type=color]::-webkit-color-swatch,
    &>input[type=color]::-webkit-color-swatch-wrapper {
      border-radius: 4px !important;
      max-width: 40px;
      overflow: hidden;
    }

  }
    
}

#app.c-body .c-main.v .container-fluid .box-banner.new-template {
  .form-row.overloaded {
    grid-template-columns: repeat(auto-fit, minmax(125px, 100%));
  }
  .box-button {
    padding-top: 0;
    grid-template-columns: repeat(auto-fit, minmax(125px, .3fr));
  }
}

#app.c-body .c-main.f .container-fluid .box-banner.new-template {
  div[data-element=leader] {
    grid-column: 1 / -1;
    @media (min-width: $breakpoint-lg){
      grid-column: auto  / span 2;
    }
  }
}



.no-border {
  border: 0 !important;
}

.text_align_select {
  border: none !important;
  border-radius: 0 !important;
  width: 400px;
  overflow: hidden;
}    

.text_field_new{
  padding-right: 35px; 
  border-radius: 0 0 10px 10px !important;
}

.dropdown-toggle{
  border-radius: 5px;
  border: 1px solid #ededed;
  padding: 2px 5px 4px 7px;
  font-size: 0.9em;
  background-color: white;
}

.dropdown-toggle:hover{
  background-color: #ebebeb;
}

.dropdown {
position: relative;
display: inline-block;
}

.dropdown-menu {
display: none;
position: absolute;
background-color: #f9f9f9;
min-width: 160px;
box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
z-index: 1000;
}

.dropdown-menu a {
color: black;
text-decoration: none;
display: block;
}

.dropdown-menu a:hover {
  background-color: #f1f1f1;
}

.menu-wide{
  width: 250px !important;
  padding: 20px !important;
  padding-right: 0px !important;
}

.text-new-label {
  justify-content:left !important;
  border: 1px solid #c7c0c0;
  padding: 6px 0 4px 9px;
  margin-bottom: 0;
  border-bottom: none;
  border-radius: 10px 10px 0 0;
  overflow:visible
}
a.increase_tracking, 
a.decrease_tracking,
a.increase_leading, 
a.decrease_leading
{
  cursor:pointer;
  padding  : 0 !important;   
}