.c-icon.grid-view,
.c-icon.group-preview {
    cursor: pointer;
}

.grid-stack-row {
    justify-content: center;
    display: flex;
}

.grid-stack-col {
    display: inline-flex;
}

.grid-stack-item {
    display: flex;
    justify-content: center;
    position: relative;

    .template-resizer {
        position: absolute;
        bottom: 6px;
        right: 6px;
        cursor: nwse-resize;
    }
}

.grid-stack-item-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

#template-grid-layout {
    .grid-stack-item-content {
        background-color: #ccc;
    }
    .grid-stack-item-template{
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
    }
}

#template-group-preview {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    z-index: 4;

    &.align-left {
        .grid-stack-row {
            justify-content: start;
        }
    }
}

.grid-stack-item-remove {
    position: absolute;
    top: 0;
    right: 5px;
    cursor: pointer;
}

#text-editor-control {
    position: absolute;
    border-radius: 15px;
    background: white;
    border: 1px solid #e1e1e1;
    min-width: 650px;
    padding: 0 5px 5px 5px;
}