.publish-to-platform {
    .instacart-fields {
        label {
            word-break: break-all;
        }

        .instacart-products {
            max-height: 500px;
            overflow: auto;
            padding: 0 10px;
        }
    }
}
