@import "variables";

.btn-control {
  height: calc(2.5em + 0.75rem + 2px);
  width: 100%;
  border: 1px solid transparent;
  border-radius: 1rem;
  font-family: $font-family-sans-serif;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  color: white;
  background-color: $secondary;
  transition: 0.2s;

  &:hover {
    background-color: darken($secondary, 10%);
    transition: 0.2s;
  }

  &--secondary {
    background-color: white;
    border: 1px solid $greyscale200;
    color: $greyscale300;

    &:hover {
      background-color: darken(white, 10%);
      border: 1px solid $greyscale300;
      transition: 0.2s;
    }
  }
}

.btn-custom {
  color: white;
  background-color: $primary200 !important;
  border-color: $primary200;

  &:hover {
    background-color: $primary300 !important;
    border-color: $primary300 !important;
  }

  &:focus {
    box-shadow: none !important;
    border: 1px solid $primary200 !important;
    background-color: $primary200 !important;
  }

  &:active {
    box-shadow: none !important;
    border: 1px solid $primary200 !important;
    background-color: $primary200 !important;
  }

  &:disabled {
    box-shadow: none !important;
    border: 1px solid $primary200 !important;
    background-color: $primary200 !important;
    cursor: default !important;
  }
}

.btn-outline-custom {
  color: $primary300 !important;
  background-color: transparent !important;
  border-color: $primary300 !important;

  &:hover {
    border-color: $primary300 !important;
  }

  &:focus {
    box-shadow: none !important;
    border: 1px solid $primary300 !important;
    background-color: transparent !important;
  }

  &:active {
    box-shadow: none !important;
    border: 1px solid $primary300 !important;
    background-color: transparent !important;
  }

  &:disabled {
    box-shadow: none !important;
    border: 1px solid $greyscale400 !important;
    color: $greyscale400 !important;
    background-color: transparent !important;
    cursor: default !important;
  }
}
