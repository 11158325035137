@import 'variables';

.navbar-custom {
  background-color: $greyscale50;
  position: sticky!important;
  z-index: 1040;
  top: 0;
  flex-flow: row nowrap;
  justify-content: flex-start;

  .c-header-toggler {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .container-fluid {
    flex-wrap: wrap!important;

    .c-header-brand {
      width: 100%;
      
      img {
        width: 50%;
        height: auto;
      }
    }
  }

  #navbarSupportedContent {
    display: flex;

    .icon-wrapper {
      align-self: center;
      line-height: 30px;
      position: relative;
      border-radius: 50em;
      width: 36px;
      height: 36px;
      background-color: white;
      margin-right: 15px;
      cursor: pointer;

      .notification-dot {
        height: 8px;
        width: 8px;
        background-color: $danger;
        border-radius: 50%;
        border: 1px solid white;
        display: inline-block;
        position: relative;
        top: 11px;
        left: 22px;
      }

      .bell-icon {
        position: absolute;
        stroke: #464646;
        left: 5px;
        top: 3px;
      }
    }
  }
  
  #navbarDropdown {
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: $greyscale400;
    
    &::after {
      display: none;
    }
    
    .bi {
      font-size: 12px;
      vertical-align: 0.01em;
      margin-left: 7px;
      
      &::before {
        transition: transform 0.1s ease-in;
        transform: rotate(0deg);
      }
      
      &__show {
        &::before {
          transition: transform 0.1s ease-in;
          transform: rotate(180deg);
        }
      }
    }

    .link-content {
      display: flex;
      align-items: center;

      .c-avatar {
        margin-right: 7px;
      }
    }
  }
}