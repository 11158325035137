@import "../variables";

#app.c-body .c-main .container-fluid .box-banner.amazon-fresh,
#app.c-body .c-main.h .container-fluid .box-banner.amazon-fresh {
  .form-row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(125px, 1fr))!important;
    margin-top: 0!important;
    &>div {
      min-width: 100%;
    }
  }
  label {
    //text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    //max-height: 1.2em;
    //max-width: 100%;
  }
  
  .headline-label {
    margin-top: 15px;
  }

  .two-color-picker {
    .background-color {
      margin: 2px 0 0 0;
    }
  }
  
  .box-button {
    padding-top: 1rem!important;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(125px, .3fr))!important;
    margin-top: 0;
    button {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-height: 38px;
      max-width: 100%;
      &[bg="fill"] {
        background-color: $primary200 !important;
        color: white !important;
        border: none !important;
      };
    }
  }

}

#app.c-body .c-main .container-fluid .box-banner.amazon-fresh,
#app.c-body .c-main.v .container-fluid .box-banner.amazon-fresh {
  .form-row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr))!important;
    margin-top: 0!important;
    &>div {
      min-width: 100%;
    }
  }

  .headline-label {
    margin-top: 0;
  }
  
  .box-button {
    padding-top: 0!important;
    grid-template-columns: repeat(auto-fit, minmax(125px, .3fr))!important;
  }
}
