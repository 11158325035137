@import "variables";

.breadcrumbs {
  padding: 0 0 0 75px;

  @media (max-width: $breakpoint-sm) {
    padding: 0 0 0 0;
  }

  .header {
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    color: $primary700;
  }

  .breadcrumb-wrapper {
    display: none;
  }

  .breadcrumb {
    background-color: transparent;
    padding: 12px 0 20px 20px;

    .home-link {
      line-height: 18px;

      .home {
        svg {
          width: 17px;
          height: 17px;

          path {
            fill: $primary200;
          }
        }
      }
    }

    .bi.bi-chevron-right {
      font-size: 14px;
      line-height: 24px;
      margin: 0 7px 0 7px;
      color: $primary200;
    }

    .breadcrumb-item {
      font-family: $font-family-sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: $greyscale300;
      cursor: pointer;

      @media (max-width: $breakpoint-sm) {
        font-size: 12px;
      }

      a {
        color: $greyscale300;

        &:hover {
          text-decoration: none;
          color: $greyscale400;
        }
      }
    }

    .breadcrumb-item.active {
      color: $greyscale400;
    }

    .breadcrumb-item + .breadcrumb-item::before {
      display: none;
    }

    .breadcrumb-item + .breadcrumb-item {
      padding: 0;
    }
  }
}

.file-image-wrapper {
  font-family: $font-family-sans-serif;
  border: none!important;
  margin: 0 auto;
}

.file-image-management {
  padding-bottom: 20px;

  .file-image-action {
    margin-right: 40px;
  }

  .file-image-action {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: $greyscale300!important;
    text-decoration: none!important;
    pointer-events: auto;

    &.active_tab {
      padding: 6px 23px;
      border-radius: 12px;
      background-color: $primary300;
      color: $greyscale50!important;
      pointer-events: none;
    }
  }
}

.file-image-content-wrapper {
  border: 1px solid $greyscale300;
  border-radius: 20px;
  padding: 35px 35px 35px 35px;

  .action {
    display: grid;
    grid-template-columns: .8fr 1fr 1fr .1fr;
    grid-auto-rows: 1fr;
    margin-bottom: 30px;
    padding: 0 70px 0 70px;

    .action-control {
      display: grid;
      grid-template-columns: 24px 24px 24px;
      align-self: center;

      .file-operations {
        display: grid;

        ul {
          margin: 30px 0 0 0!important;
        }

        .bytesize-options {
          svg {
            width: 16px;
            height: 16px;
            stroke: $greyscale300;
          }
        }
      }

      .group {
        svg {
          width: 18px;
          height: 16px;
          stroke: $greyscale300;
        }
      }

      .entypo-list {
        svg {
          width: 20px;
          height: 20px;

          path {
            fill: $greyscale300;
          }
        }
      }
    }

    .search-wrapper {
      position: relative;

      .big-search {
        position: absolute;
        right: 15px;
        top: 4px;

        svg {
          width: 13px;
          height: 13px;
          path {
            fill: $greyscale200;
          }
        }
      }

      .file-new-button {
        display: none;
        position: absolute;
        right: -50px;
        top: 2px;
        border: none;
        color: transparent;
        background-color: transparent;

        .file-new-folder {
          svg {
            width: 28px;
            height: 30px;
          }
        }
      }

      .file-back-button {
        display: none;
        position: absolute;
        right: -84px;
        top: 4px;
        font-size: 22px;
        border: none;
        color: rgb(116, 116, 116);
        background-color: transparent;
      }

      .file-link-button {
        display: none;
        position: absolute;
        right: -118px;
        top: 0px;
        font-size: 22px;
        border: none;
        color: rgb(116, 116, 116);
        background-color: transparent;
      }

      .file-upload-button {
        display: none;
        position: absolute;
        right: -148px;
        top: 0px;
        font-size: 22px;
        border: none;
        color: rgb(116, 116, 116);
        background-color: transparent;
      }

      #index-search-input {
        font-family: $font-family-sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: $greyscale400;

        width: 100%;
        height: 35px;
        background: white;
        border: 1px solid $greyscale200;
        border-radius: 12px;
        padding: 0 35px 0 8px;

        &::placeholder {
          font-family: $font-family-sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 10.6px;
          color: $greyscale200;
        }

        &:focus {
          border: 1px solid $greyscale200!important;
          outline: none!important;
        }

        &:focus-visible {
          border: 1px solid $greyscale200!important;
          outline: none;
        }
      }
    }

    .cancel-selected-btn {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      #global-download-unselect {
        display: none;
        opacity: 1!important;

        &:hover {
          opacity: 0.75!important;
        }

        span {
          font-style: normal!important;
          font-weight: 700!important;
          font-size: 14px!important;
          color: $greyscale300!important;
        }
      }
    }
    .settings-btn-container{
      padding-left: 10px;
    }
  }

  .image-grid-responsive {
    display: grid;
    min-height: 640px !important;
    padding: 0 !important;
    overflow-y: auto!important;

    /* width */
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    &.drop-message {
      &::after {
        content: 'Drag & drop files here';
        display: flex;
        justify-content: center;
        font-size: 20px;
        font-family: $font-family-sans-serif;
        opacity: 0.4;
      }
    }

    .grid {
      display: grid;
      grid-template-columns: repeat(9, minmax(100px, 1fr));
      grid-template-rows: 180px auto;
      column-gap: 30px;
      row-gap: 70px;
      justify-self: center;
    }

    @media (max-width: 2300px) {
      .grid {
        grid-template-columns: repeat(8, minmax(100px, 1fr)) !important;
      }
    }

    @media (max-width: 2100px) {
      .grid {
        grid-template-columns: repeat(7, minmax(100px, 1fr)) !important;
      }
    }

    @media (max-width: 1900px) {
      .grid {
        grid-template-columns: repeat(6, minmax(100px, 1fr)) !important;
      }
    }

    @media (max-width: 1700px) {
      .grid {
        grid-template-columns: repeat(5, minmax(100px, 1fr)) !important;
      }
    }

    @media (max-width: 1500px) {
      .grid {
        grid-template-columns: repeat(4, minmax(100px, 1fr)) !important;
      }
    }

    @media (max-width: 1300px) {
      .grid {
        grid-template-columns: repeat(2, minmax(100px, 1fr)) !important;
      }
    }

    .grid {
      .name-input {
        display: none;
        position: absolute;
        top: 149px;
        width: 150px;
        border: none;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: $greyscale400;
        text-align: center;

        &:focus {
          border: none !important;
          outline: none!important;
        }

        &:focus-visible {
          border: none !important;
          outline: none !important;
        }
      }

      &.loading {
        display: flex;
        align-self: center;
      }
      
      .grid-item {
        width: 180px;
        height: 144px;
        float: inherit;
        border: 1px solid $greyscale100!important;
        background: white;
        box-sizing: border-box;
        box-shadow: $image-shadow;
        border-radius: 10px;
        position: relative;
        transition: background-color ease-in-out .4s!important;

        .table-action-buttons{
          display: none;
        }

        .stock-actions{
          position: absolute;
          top: 5px;
          display: flex;
          right: 5px;

          svg {
            width: 22px;
          }

          #stock-circle-close {
            display: none;
            cursor: pointer;
            position: absolute;
            z-index: 2;
            top: 2px;
            right: 1px;
            width: 20px;
            height: 20px;
            fill: $greyscale300;
          }

          .stock-edit {
            display: none;
            cursor: pointer;
            position: absolute;
            z-index: 2;
            top: 0px;
            right: 23px;
            width: 20px;
            height: 20px;
            color: $greyscale300;

            i {
              font-size: 14px;
            }
          }
        }

        .table-action-buttons{
          display: none;
        }

        .file-folder {
          position: relative;
          padding: 1px;
          width: 175px;
          height: 144px;
          overflow: hidden;

          .loader-container{
            width: 180px;
            height: 140px;
            background-color: rgba(0, 0, 0, 0.4);
            -webkit-border-radius: 5%;
            -moz-border-radius: 5%;
            border-radius: 5%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            padding: 0 10px 0 5px;
            z-index: 9;

            .progress {
              width: 100%;
            }
          }

          img {
            position: absolute;
          }

          .video-container, video {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
          }

          video {
            pointer-events: none;
          }

          #file-circle-close {
            display: none;
            cursor: pointer;
            position: absolute;
            z-index: 2;
            top: 2px;
            right: 1px;
            width: 20px;
            height: 20px;
            fill: $greyscale300;
          }

          .file-edit {
            display: none;
            cursor: pointer;
            position: absolute;
            z-index: 2;
            top: 0px;
            right: 23px;
            width: 20px;
            height: 20px;
            color: $greyscale300;

            i {
              font-size: 14px;
            }
          }

          #file-folder-svg {
            position: absolute;
            top: 6px;
            width: 180px;
            height: 140px;
            fill: $greyscale300;
          }

          #file-psd-svg, #file-any-svg {
            position: absolute;
            top: 6px;
            left: 10px;
            width: 155px;
            height: 130px;
            fill: $greyscale300;
          }

          #file-svg-svg {
            position: absolute;
            top: -29px;
            left: 7px;
            width: 168px;
            height: 200px;
            fill: $greyscale300;
          }

          #file-tiff-svg {
            position: absolute;
            top: 0;
            left: 5px;
            width: 143px;
            height: 143px;
            fill: $greyscale300;

            g > path {
              fill: $greyscale300;
            }
          }
          
          #file-webm-svg {
            position: absolute;
            top: 6px;
            left: 5px;
            width: 170px;
            height: 130px;
            fill: $greyscale300;
          }

          #file-doc-svg, #file-pdf-svg, #file-xls-svg, #file-zip-svg, #file-mp4-svg, #file-mp3-svg, #file-avi-svg, #file-mov-svg, #file-ppt-svg, #file-pptx-svg, #file-gif-svg, #file-ai-svg, #file-vtt-svg, #file-eps-svg {
            position: absolute;
            top: 6px;
            left: 5px;
            width: 160px;
            height: 130px;
            fill: $greyscale300;
          }

          #file-loading-svg{
            position: absolute;
            width: 65px;
            height: 65px;
            top: 38px;
            left: 60px;
            fill: $greyscale300;
            -webkit-animation:spin 2s linear infinite;
            -moz-animation:spin 2s linear infinite;
            animation:spin 2s linear infinite;
            z-index: 8;
          }

          @-moz-keyframes spin {
            100% { -moz-transform: rotate(360deg); }
          }
          @-webkit-keyframes spin {
            100% { -webkit-transform: rotate(360deg); }
          }
          @keyframes spin {
            100% {
              -webkit-transform: rotate(360deg);
              transform:rotate(360deg);
            }
          }
        }

        &.drag-hover {
          border: 4px solid #209c86 !important;
        }

        &.selected {
          border: 4px solid #209c86 !important;

          .file-folder {
            padding: 0;

            #file-circle-close {
              top: 1px;
              right: 0;
            }

            #file-folder-svg {
              top: 5px;
            }

            #file-doc-svg, #file-pdf-svg, #file-xls-svg, #file-zip-svg, #file-mp4-svg, #file-webm-svg, #file-mp3-svg, #file-avi-svg, #file-mov-svg, #file-ppt-svg, #file-pptx-svg, #file-gif-svg, #file-ai-svg, #file-vtt-svg, #file-eps-svg {
              top: 5px;
              left: 4px;
            }

            #file-loading-svg {
              top: 37px;
              left: 60px;
            }

            #file-psd-svg, #file-any-svg {
              top: 5px;
              left: 9px;
            }

            #file-svg-svg {
              top: -30px;
              left: 7px;
            }

            #file-tiff-svg {
              position: absolute;
              top: -1px;
              left: 4px;
            }
          }

          .grid-image-title {
            top: 149px;
          }

          img {
            padding: 19px!important;
          }
        }

        &:hover {
          transition: ease-in-out!important;
          border: 4px solid #209c86 !important;

          .file-folder {
            padding: 0;

            #file-circle-close {
              display: block;
              top: 2px;
              right: 5px;
            }

            .file-edit {
              display: block;
              top: 0px;
              right: 25px;
            }

            #file-folder-svg {
              top: 5px;
            }

            #file-doc-svg, #file-pdf-svg, #file-xls-svg, #file-zip-svg, #file-mp4-svg, #file-webm-svg, #file-mp3-svg, #file-avi-svg, #file-mov-svg, #file-ppt-svg, #file-pptx-svg, #file-gif-svg, #file-ai-svg, #file-vtt-svg, #file-eps-svg {
              top: 5px;
              left: 4px;
            }

            #file-loading-svg {
              top: 37px;
              left: 60px;
            }

            #file-psd-svg, #file-any-svg {
              top: 5px;
              left: 9px;
            }

            #file-svg-svg {
              top: -30px;
              left: 7px;
            }

            #file-tiff-svg {
              position: absolute;
              top: -1px;
              left: 4px;
            }

            img {
              padding: 20px!important;
            }
          }

          .stock-actions{
            #stock-circle-close {
              display: block;
              top: 2px;
              right: 5px;
            }

            .stock-edit {
              display: block;
              top: 0px;
              right: 25px;
            }
          }

          .select-check {
            transition: ease-in-out!important;
            opacity: 0!important;
          }

          .grid-image-title {
            transition: ease-in-out!important;
            top: 149px;
          }

          img {
            transition: ease-in-out!important;
            padding: 19px!important;
          }
        }

        &.no-hover {
          &:hover {
            border: 1px solid $greyscale100!important;

            .file-folder {
              padding: 1px;

              #file-circle-close {
                display: none;
                top: 2px;
                right: 0;
              }

              .file-edit {
                display: none;
                top: 0px;
                right: 0;
              }

              #file-folder-svg {
                top: 6px;
              }

              #file-doc-svg, #file-pdf-svg, #file-xls-svg, #file-zip-svg, #file-mp4-svg, #file-webm-svg, #file-mp3-svg, #file-avi-svg, #file-mov-svg, #file-ppt-svg, #file-pptx-svg, #file-gif-svg, #file-ai-svg, #file-vtt-svg, #file-eps-svg {
                top: 6px;
                left: 5px;
              }

              #file-loading-svg {
                top: 38px;
                left: 60px;
              }

              #file-psd-svg, #file-any-svg {
                top: 6px;
                left: 10px;
              }

              #file-svg-svg {
                top: -29px;
                left: 7px;
              }

              #file-tiff-svg {
                position: absolute;
                top: 0;
                left: 5px;
              }
            }

            .stock-actions{
              padding: 1px;

              #stock-circle-close {
                display: none;
                top: 2px;
                right: 0;
              }

              .stock-edit {
                display: none;
                top: 0px;
                right: 0;
              }
            }

            .select-check {
              transition: ease-in-out!important;
              opacity: 0!important;
            }

            .grid-image-title {
              transition: ease-in-out!important;
              top: 150px;
            }

            img {
              transition: ease-in-out!important;
              padding: 20px!important;
            }
          }
        }

        &.no-hover-selected {
          &:hover {
            .file-folder {
              #file-circle-close {
                display: none;
              }
              .file-edit {
                display: none;
              }
            }
            .stock-actions{
              #stock-circle-close {
                display: none;
              }
              .stock-edit {
                display: none;
              }
            }
          }
        }

        &.on-drag {
          background-color: $greyscale50;
          transition: background-color ease-in-out .4s!important;
        }

        img {
          transition: ease-in-out!important;
          padding: 20px!important;
          width: 100%!important;
          height: 100%!important;
        }

        .select-check {
          transition: ease-in-out!important;
          opacity: 0!important;
        }

        .grid-image-title {
          transition: ease-in-out!important;
          width: 100%;
          word-break: break-all;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          color: $greyscale400;
          position: absolute;
          top: 150px;
        }
        .grid-image-size{
          display: none;
        }
        .grid-image-type{
          display: none;
        }
        .grid-image-created{
          display: none;
        }
      }
    }

    .file-view-table{
      display: flex!important;
      flex-direction: column;
      width: 100%;
      row-gap: 10px;
      align-items: center;

      .grid-item{
        border: none!important;
        box-shadow: none;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .table-action-buttons{
          display: flex;
          -webkit-column-gap: 10px;
          -moz-column-gap: 10px;
          column-gap: 10px;
          justify-content: center;
          align-items: center;

          .file-edit, .file-delete{
            width: 25px;
            height: 25px;
            font-size: 16px;
            display: block!important;
          }
        }

        .grid-image-title{
          position: relative;
          left: 0;
          top: 0!important;
        }

        .grid-image-size{
          display: block;
          width: 150px;
          font-size: 14px;
        }
        .grid-image-type{
          display: block;
          width: 115px;
          font-size: 14px;
        }
        .grid-image-created{
          display: block;
          width: 150px;
          font-size: 14px;
        }

        .file-folder{
          width: 30px;
          height: 30px;

          #file-folder-svg{
            width: 30px;
            height: 30px;
          }

          img{
            width: 30px!important;
            height: 30px!important;
            padding: 0px!important;
            margin-left: 4px;
          }
        }

        svg{
          width: 30px!important;
          height: 30px!important;
          position: relative!important;
          top: 0px!important;
          left: 0px!important;
        }

        #file-circle-close, .file-edit{
          display: none!important;
        }

        .select-check {
          opacity: 1 !important;
          position: relative!important;
          margin-right: 10px;
        }

        &.no-hover:hover{
          border: none!important;

          img{
            padding: 0px!important;
          }

          .select-check {
            opacity: 1 !important;
            position: relative!important;
            margin-right: 10px;
          }
        }

        &.selected{
          border: none!important;
        }

        &:hover{
          border: none!important;

          .file-folder{
            img {
              padding: 0px!important;
            }
          }

          .grid-image-title{
            top: 0!important;
            font-weight: 500;
          }
          
          .select-check {
            opacity: 1 !important;
            position: relative!important;
            margin-right: 10px;
          }
        }
      }
    }

    .grid-header{
      display: flex;
      height: 60px;
      float: inherit;
      background: white;
      box-sizing: border-box;
      border-radius: 10px;
      position: relative;
      transition: background-color ease-in-out 0.4s !important;
      box-shadow: none;
      width: 100%;
      justify-content: center;
      align-items: center;
      
      .grid-image-title{
        position: relative;
        left: 0;
        top: 0!important;
        transition: ease-in-out!important;
        width: 100%;
        word-break: break-all;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: $greyscale400;
      }

      .grid-image-size{
        display: block;
        width: 150px;
        font-size: 14px;
      }
      .grid-image-type{
        display: block;
        width: 115px;
        font-size: 14px;
        
        .filter-file-type{
          padding: 2px 1px;
          /* padding-right: 1px; */
          width: 90%;
          margin-left: auto;
          margin-right: auto;
          font-size: 14px;
        }
      }
      .grid-image-created{
        display: block;
        width: 150px;
        font-size: 14px;
      }
      p{
        font-size: 14px;
      }
    }
  }

  .drop-area {
    border: 2px dashed #ccc;
    border-radius: 20px;
    width: 100%;
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    color: #ccc;
  }

  .drop-area.highlight {
      border-color: purple;
  }

  .c-main-view {
    border: 1px solid #727272;
    border-radius: 20px;
    padding: 35px 35px 35px 35px;
    position: relative;

    &__drag-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #a0a0a0;
      opacity: 0.15;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px dashed #727272;

      &--text {
        color: black;
        font-size: 36px;
      }
    }
  }

  .btn.btn-primary {
    color: $primary300!important;
    background-color: transparent!important;
    border-color: $primary300!important;
    transition: 0.2s;
    border-radius: 8px;
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    margin: 0 5px;

    &:hover {
      transition: 0.2s;
      border-color: $primary300!important;
    }

    &:focus {
      box-shadow: none!important;
      border: 1px solid $primary300!important;
      background-color: transparent!important;
    }

    &:active {
      box-shadow: none!important;
      border: 1px solid $primary300!important;
      background-color: transparent!important;
    }

    &:disabled {
      box-shadow: none!important;
      border: 1px solid $greyscale400!important;
      color: $greyscale400!important;
      background-color: transparent!important;
      cursor: default!important;
    }
  }

  .image-grid-pagination {
    display: grid;
    justify-items: center;
    grid-template-columns: 1fr;

    .paginationjs.image-paginationjs {
      .paginationjs-pages {
        .paginationjs-prev, .paginationjs-next {
          position: relative;
          border: none;

          &.disabled {
            opacity: 0;
          }

          &:hover {
            border: none!important;
            background: transparent!important;
          }

          a {
            position: absolute;
            background-color: $primary200;
            border-radius: 50px;
            height: 24px;
            width: 24px;
            top: -2px;
            left: 4px;

            .bi.bi-arrow-left-short, .bi.bi-arrow-right-short {
              font-size: 25px;
              position: absolute;
              left: -0.5px;
              top: -4.6px;
              color: white;
            }
          }
        }

        .paginationjs-page.J-paginationjs-page {
          font-family: $font-family-sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          color: $greyscale300;
          border: none;

          &:hover {
            border: none!important;
            background: transparent!important;
            color: $greyscale300;

            a {
              color: $greyscale300;
            }
          }

          a {
            color: $greyscale300;
          }

          &.active {
            background: $primary200;
            padding: 9px 12px 9px 11px;
            border-radius: 14px;

            a {
              color: white;
            }

            &:hover {
              background: $primary200!important;

              a {
                color: white;
              }
            }
          }
        }

        .paginationjs-ellipsis {
          padding: 0!important;
        }
      }
    }
  }

  .image-grid-btn {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  .download-block {
    float: none!important;
  }

  .image-grid-download-btn {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;

    p {
      width: 100%;
      text-align: end;
      margin-bottom: 5px;
      margin-right: 10px;
    }
  }

  @import "data-tables";
}

#delete-folder-modal,
#shareUserFilesModal,
#preview-user-file-modal,
#edit-folder-modal,
#edit-layout-folder-modal {
  font-style: normal!important;
  font-weight: 500!important;
  font-size: 14px!important;
  color: $greyscale400!important;
  font-family: $font-family-sans-serif;

  .modal-content {
    padding: 23px 6px;
    font-size: 1rem;
    
    &.bordered {
      border: 1px solid $greyscale200;
    }

    .modal-content-inner {
      padding: 0 !important;
    }

    input:not(.share-proof-url), select:not(.share-proof-url) {
      font-family: "Nunito", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #464646;
      width: 100%;
      height: 35px;
      background: white;
      border: 1px solid #b1b1b1;
      border-radius: 12px;
      padding: 0 8px 0 8px;
    }

    .bootstrap-tagsinput{
      input{
        width: auto!important;
        border: none!important;
        -webkit-border-radius: 0px!important;
        -moz-border-radius: 0px!important;
        border-radius: 0px!important;
      }
    }
  }

  .modal-header {
    padding: 0 10px;
    border: none;
    font-size: 20px;
    font-weight: 700;

    button {
      padding: 15px 15px 0 0;
    }
  }

  .modal-footer {
    padding: 0 10px;
    border: none;

    button {
      font-family: $font-family-sans-serif;
      padding: 5px 10px 5px 10px;
      font-size: 18px;
      line-height: 1.4;
      border-radius: 8px;
      border: 1px solid #209c86;

      &.btn-primary {
        background-color: $primary200;
        color: white;
      }

      &.btn-secondary {
        background-color: white;
        color: $primary200;
      }
    }
  }
}
