#bulkUpdateModal.bulk-update {
  .modal-content {
    border: none;
    border-radius: 20px;
    background-color: #F6F6F6;
    .modal-header {
      border-bottom: none;
      h5#bulkUpdateModalLabel {
        font-family: $font-family-sans-serif;
        font-size: 24px;
        line-height: 1.375;
        font-weight: 800;
        color: $greyscale400;
      }
      button.close {
        color: $greyscale400;
      }
    }
    .modal-body {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      .table {
        thead {
          th {
            border-top: none;
            border-bottom: 1px solid #E1E1E1;
            font-family: $font-family-sans-serif;
            font-size: 16px;
            line-height: 1.357;
            font-weight: 700;
            color: $primary300;
          }
        }
        tbody {
          .form-check {
            input[type=checkbox] {
              position: absolute;
              z-index: -1;
              opacity: 0;
            }
            label {
              font-family: $font-family-sans-serif;
              font-size: 14px;
              line-height: 1.357;
              font-weight: 700;
              color: $greyscale300;
              display: inline-flex;
              align-items: center;
              user-select: none;
              &::before {
                content: "";
                display: inline-block; 
                width: 1rem;
                height: 1rem;
                border: 1px solid $greyscale100;
                border-radius: 0.25em;
                margin-right: 0.5em;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 50% 50%;
              }
            }
            input[type=checkbox]:checked+label::before {
              border-color: $primary200;
              background-color: $primary200;
              background-size: cover;
              background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
            }
            input[type=checkbox]:not(:disabled):not(:checked)+label:hover::before {
              border-color: $primary100;
            }
            input[type=checkbox]:not(:disabled):not(:checked)+label:hover {
              color: $primary100;
            }
          }
        }
      }
    }
    .modal-footer {
      border-top: none;
      p {
        font-family: $font-family-sans-serif;
        font-size: 16px;
        line-height: 1.375;
        font-weight: 500;
        color: $greyscale400;
        span.important {
          font-weight: 700;
          color: $secondary;
        }
      }
      button.btn-primary {
        height: 38px;
        border: none;
        border-radius: 6px;
        font-family: $font-family-sans-serif;
        font-size: 12px;
        line-height: 1.333;
        font-weight: 700;
        background-color: $primary200;
        color: #fff;
      }
      button.btn-secondary {
        height: 38px;
        border: 1px solid $primary200;
        border-radius: 6px;
        font-family: $font-family-sans-serif;
        font-size: 12px;
        line-height: 1.333;
        font-weight: 700;
        background-color: transparent;
        color: $primary200;
      }
    }
  }
}