.select-custom {
  position: relative;
  font-family: Arial;
}

.select-custom select {
  display: none;
  /*hide original SELECT element:*/
}

/*style the arrow inside the select element:*/
.select-selected:after {
  position: absolute;
  content: "";
  top: 16px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #444 transparent transparent transparent;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #444 transparent;
  top: 10px;
}

.select-selected {
  cursor: pointer;
  user-select: none;
}

/*style the items (options), including the selected item:*/
.select-items div {
  background: white;
  color: #000;
  padding: 8px 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  user-select: none;
}

/*style items (options):*/
.select-items {
  position: absolute;
  top: 100%;
  left: 5px;
  right: 0;
  width: calc(100% - 10px);
  z-index: 99;
  max-height: 300px;
  overflow-y: scroll;
}

/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}

.option-item:hover {
  background-color: lightblue;
}

.option-item span:first-child {
  display: inline-block;
  width: calc(100% - 30px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.option-item .color-pane {
  width: 30px;
  height: 20px;
  float: right;
}

.safe-zone-list {
  position: absolute;
  background: white;
  z-index: 1031;
  max-height: 300px;
  border: 1px solid;
  cursor: pointer;
  right: 32px;
  top: 32px;
  list-style: none;
  padding: 0px 10px;
  width: 150px;

  li.checked {
    background-color: lightgrey;
  }

  li:hover {
    background-color: #888888;
  }
}

.select2-container .select2-selection--single {
  /*height: 38px !important;*/
  border-radius: 2px !important; 
  border: 1px solid #ededed !important;
  font-size: 0.9em;
}

.select2-container .select2-selection--single:hover {
   background-color: #ebebeb !important;
}



/*
.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 38px !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 36px !important;
}
*/
.list-field-type {
  position: absolute;
  background: white;
  z-index: 1031;
  max-height: 400px;
  overflow-y: scroll;
  border: 1px solid;
  cursor: pointer;
  right: 125px;
  top: 32px;
  list-style: none;
  padding: 0px 10px;

  li:hover {
    background-color: #888888;
  }
}

#file_ids_dropdown {
  width: calc(60vw - 9px);
  height: 435px;
  position: absolute;
  background: rgb(255, 255, 255);
  z-index: 101;
  top: 38px;
  border: 1px solid;
  box-shadow: 2px 2px #888888;
  overflow-y: auto;
  padding: 20px;

  .empty-files {
    height: 100%;
  }

  .product-grid-item {
    width: 20%;
    height: 150px;
    float: left;
    border: 1px solid transparent;
    user-select: none;
    position: relative;
    img {
      height: calc(100% - 35px);
      max-width: 100%;
      object-fit: contain;
      display: block;
      margin: 0 auto;
    }

    .item-filename {
      word-wrap: break-word;
      line-height: 1;
      text-align: center;
      width: 100%;
      display: block;
    }

    p {
      margin: 0;
      text-align: center;
    }

    .budge {
      color: white;
      font-size: 12px;
      background: red;
      padding: 0px 3px;
      border-radius: 5px;
      position: absolute;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 35px;
      display: flex;
      align-items: center;
      border-radius: 5px;

      a {
        margin: 0 auto;
        padding: 0 1rem;
        color: white;
        border-radius: 5px;
        background-color: #000015b3;
      }
    }

    &.image-none {
      .grey-background {
        width: 100px;
        height: 100%;
        margin: 0 auto;
        background-color: #888888;
      }
    }

    .product-grid-item-controls {
      position: absolute;
      left: 0;
      top: 4px;
      opacity: 0;

      &.is-check {
        opacity: 1;
      }

      .product-grid-item-quantity {
        margin: 4px 4px 0 4px;
      }
      .product-grid-item-minus, .product-grid-item-plus {
        width: 30px;
        height: 30px;
        border-radius: 15px;
      }
    }

    &:hover,
    &.selected {
      .product-grid-item-controls {
        opacity: 1;
      }
    }
  }
}

.no-border {
  border: 0 !important;
}

.text_align_select {
  border: none !important;
  border-radius: 0 !important;
  width: 400px;
  overflow: hidden;
}    

.text_field_new{
  padding-right: 35px; 
  border-radius: 0 0 10px 10px !important;
}

.dropdown-toggle{
  border-radius: 5px;
  border: 1px solid #ededed;
  padding: 2px 5px 4px 7px;
  font-size: 0.9em;
  background-color: white;
}

.dropdown-toggle:hover{
  background-color: #ebebeb;
}

.dropdown {
position: relative;
display: inline-block;
}

.dropdown-menu {
display: none;
position: absolute;
background-color: #f9f9f9;
min-width: 160px;
box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
z-index: 1000;
}

.dropdown-menu a {
  color: black;
  text-decoration: none;
  display: block;
}

.dropdown.shared-proof-table-actions.show {
  .ellipsis-vertical {
    svg {
      width: 20px;
      height: 20px;

      path {
        fill: $secondary;
      }
    }
  }

  .dropdown-menu.show {
    .dropdown-item {
      transition: 0.2s;

      svg {
        margin-right: 10px;
        width: 20px;
        height: 20px;
        stroke: $greyscale400;
        transition: 0.2s;
      }

      &:hover {
        background-color: white;
        color: $secondary;

        svg {
          stroke: $secondary;
        }
      }
    }
  }
}

.btn.dropdown-item.action-menu {
  display: flex;
  line-height: 35px;
  color: $greyscale400;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  i {
    margin: 0 13px 18px 0;
  }

  .download-icon {
    margin: 0 10px 2px 0;

    svg {
      width: 18px;
      height: 18px;

      path {
        transition: 0.2s;
        fill: $greyscale400;
      }
    }
  }

  .pencil-alt {
    margin: 0 10px 2px 0;

    svg {
      transition: 0.2s;
      width: 18px;
      height: 19px;
      stroke: $greyscale400;
    }
  }

  .document-duplicate {
    margin: 0 10px 2px 0;

    svg {
      width: 20px;
      height: 20px;

      path {
        transition: 0.2s;
        fill: $greyscale400;
      }
    }
  }
}

.dropdown-menu a:hover {
  background-color: #f1f1f1;
}

.menu-wide{
  width: 250px !important;
  padding: 20px !important;
  padding-right: 0px !important;
}

.text-new-label {
  justify-content:left !important;
  border: 1px solid #c7c0c0;
  padding: 6px 0 4px 9px;
  margin-bottom: 0;
  border-bottom: none;
  border-radius: 10px 10px 0 0;
  overflow:visible
}
a.increase_tracking, 
a.decrease_tracking,
a.increase_leading, 
a.decrease_leading
{
  cursor:pointer;
  padding  : 0 !important;   
}

#shadow-settings-modal {
  max-width: 50vw;
  z-index: 999999;

  .modal-content {
    padding: 0 6px;
  }
  .modal-header {
    cursor: move;
  }

  .modal-dialog {
    min-width: 50vw;
    margin: 0;
  }

  .modal-body {
    max-width: 50vw;
    max-height: 300px;
    height: 100%;
    overflow-y: auto;
    padding-top: 0;

    .sh_save input, .sh_selected input {
      margin-top: 8px !important;
    }
  }

  thead {
    position: sticky;
    top: -1px;
    z-index: 1;
  }
}

#opacity-modal {
  max-width: 500px;
  width: 100%;
  height: auto;
  top: 10%;
  left: 20%;

  .modal-content {
    border: 1px solid #b1b1b1;
    padding: 0;
  }
}

#product_auto_positioning{
  height: 15px;
  width: 15px;
  accent-color: #555555;
  vertical-align: middle;
  margin-bottom: 3px;
}

.swal2-container .modal-content {
  width: 600px !important;
}

.btn-copy-file-link{
  border: 1px solid #cfddda; 
  padding: 3px 6px; 
  border-radius: 5px;
  cursor: pointer; 
  color: #209c86;
}

.w-6 {
  width: 1.5rem !important;
}

.h-6 {
  height: 1.5rem !important;
}

.w-6 {
  width: 1.5rem !important;
}

.h-6 {
  height: 1.5rem !important;
}

.w-8 {
  width: 2rem !important;
}

.h-8 {
  height: 2rem !important;
}
