@import 'variables';

.layout-table-management {
  justify-content: space-between;

  @media (max-width: $breakpoint-sm) {
    flex-direction: column-reverse!important;
  }

  .checked-layouts-dropdown {
    margin-right: 10px;
    text-decoration: none;
    border-bottom: solid 1px $greyscale400;
    .action:hover{
      text-decoration: none;
    }
    .action{
      color: $greyscale400;
      font-style: normal;
      font-size: 14px;
      font-weight: 500;
    }
    .btn.dropdown-item.action-menu {
      display: flex;
      line-height: 35px;
      color: $greyscale400;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      padding: 12px 16px;
      .trash {
        margin: 0 10px 2px 0;

        svg {
          width: 20px;
          height: 20px;

          path {
            transition: 0.2s;
            fill: $greyscale400;
          }
        }
      }

      .archive, .combined-shape, .move-from-folder {
        margin: 0 10px 3px 2px;

        svg {
          width: 20px;
          height: 20px;

          path {
            transition: 0.2s;
            fill: $greyscale400;
          }
        }
      }

      .folder {
        margin: 0 6px 3px 0;

        svg {
          width: 25px;
          height: 25px;
          path {
            transition: 0.2s;
            fill: $greyscale400;
          }
        }
      }

      .restore {
        margin: 0 10px 3px 0;

        svg {
          width: 20px;
          height: 20px;

          path {
            transition: 0.2s;
            fill: $greyscale400;
          }
        }
      }
    }
  }

  .layout-management {
    display: flex;
    width: 100%;
    align-items: baseline;
    margin-bottom: 1rem;
  }

  .management-select {
    width: auto;

    @media (max-width: $breakpoint-sm) {
      width: 100%;
    }

    .form-control {
      height: calc(2.8em + 0.8rem + 2px);
      border: 1px solid $greyscale100;
      border-radius: 16px;
      color: $greyscale400;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;

      &:focus {
        box-shadow: none;
        border: 1px solid $greyscale200;
      }
    }
  }

  .layout-tabs-wrapper {
    width: 100%;
    padding-bottom: 20px;
    position: relative;

    .layout-tabs {
      color: $greyscale400;
      font-style: normal;
      font-size: 14px;

      .layout-pill {
        margin-right: 40px;
      }

      .nav-item {
        a {
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          color: $greyscale300;
          text-decoration: none;
          pointer-events: auto;
        }

        a:hover {
          text-decoration: none
        }

        .active {
          padding: 8px 23px;
          border-radius: 12px;
          background-color: $primary300;
          color: $greyscale50;
          pointer-events: none;
        }
      }
    }

    .file-back-button {
      display: none;
      position: absolute;
      right: -7px;
      top: 2px;
      font-size: 19px;
      border: none;
      color: rgb(116, 116, 116);
      background-color: transparent;
    }
  }

  .management-action {
    margin: 0;

    .btn {
      padding: 0;

      &:focus {
        box-shadow: none;
      }
    }

    @media (max-width: $breakpoint-sm) {
      margin: 0 0 20px 0;
    }
    .layout-tabs {
      margin-right: 10px;
      display: inline-block;
      color: $greyscale400;
      font-style: normal;
      font-size: 14px;
      .nav-item{
        a {
          font-weight: 500;
          color: $greyscale400;
        }
        a:hover {
          text-decoration: none
        }
        .active {
          color: $greyscale400;
          display: none;
        }
      }
    }
  }
}

.index-wrapper {
  position: relative;

  .bi.bi-search {
    position: absolute;
    z-index: 9;
    top: 37px;
    left: 260px;
    color: $greyscale300;
  }
}

// dropdown
.layout-actions {
  display: flex;
  grid-gap: .75rem;

  .action {
    .combined-shape {
      transition: 0.2s;

      svg {
        width: 20px;
        height: 20px;
        path {
          transition: 0.2s;
          fill: $greyscale400;
        }
      }

      &:hover {
        svg {
          path {
            transition: 0.2s;
            fill: $secondary;
          }
        }
      }
    }

    .checked-actions {
      svg {
        width: 28px;
        height: 28px;
        stroke: $greyscale400;
      }

      &:hover {
        svg {
          transition: 0.2s;
          stroke: $secondary;
          fill: $secondary;
        }
      }
    }

    .pencil-alt {
      svg {
        width: 20px;
        height: 20px;
        stroke: $greyscale400;
      }

      &:hover {
        svg {
          transition: 0.2s;
          stroke: $secondary;
        }
      }
    }

    .ellipsis-vertical {
      svg {
        width: 20px;
        height: 20px;
        path {
          transition: 0.2s;
          fill: $greyscale400;
        }
      }

      &:hover {
        svg {
          path {
            transition: 0.2s;
            fill: $secondary;
          }
        }
      }
    }

    .document-duplicate {
      svg {
        width: 20px;
        height: 20px;
        path {
          transition: 0.2s;
          fill: $greyscale400;
        }
      }

      &:hover {
        svg {
          path {
            transition: 0.2s;
            fill: $secondary;
          }
        }
      }
    }
  }

  .dropdown-menu.show {
    z-index: 1100;
  }
  
  .btn.dropdown-item.action-menu {
    display: flex;
    line-height: 35px;
    color: $greyscale400;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;

    .trash {
      margin: 0 10px 2px 0;

      svg {
        width: 20px;
        height: 20px;
        path {
          transition: 0.2s;
          fill: $greyscale400;
        }
      }
    }

    .archive, .move-from-folder {
      margin: 0 10px 3px 2px;

      svg {
        width: 20px;
        height: 20px;
        path {
          transition: 0.2s;
          fill: $greyscale400;
        }
      }
    }

    .folder {
      margin: 0 6px 3px 0;

      svg {
        width: 25px;
        height: 25px;
        path {
          transition: 0.2s;
          fill: $greyscale400;
        }
      }
    }

    .restore {
      margin: 0 10px 3px 0;

      svg {
        width: 20px;
        height: 20px;
        path {
          transition: 0.2s;
          fill: $greyscale400;
        }
      }
    }

    .square-plus {
      margin: 0 10px 3px 0;

      svg {
        width: 20px;
        height: 20px;
        path {
          transition: 0.2s;
          fill: $greyscale400;
        }
      }
    }
  }
}
// fix for Popper: CSS warning

.layout-actions.show {
  .ellipsis-vertical {
    svg {
      width: 20px;
      height: 20px;
      path {
        fill: $secondary;
      }
    }
  }

  .dropdown-menu.show {
    //transition: 0.2s;

    .dropdown-item {
      transition: 0.2s;
      &:hover {
        transition: 0.2s;
        color: $secondary;
        background-color: white;

        .document-duplicate {
          margin: 0 10px 1px 0;

          svg {
            width: 20px;
            height: 20px;
            path {
              transition: 0.2s;
              fill: $secondary;
            }
          }
        }

        .trash {
          margin: 0 10px 2px 0;

          svg {
            width: 20px;
            height: 20px;
            path {
              transition: 0.2s;
              fill: $secondary;
            }
          }
        }

        .square-plus {
          margin: 0 10px 3px 0;

          svg {
            width: 20px;
            height: 20px;
            path {
              transition: 0.2s;
              fill: $secondary;
            }
          }
        }
      }
    }
  }
}

// table styles
.layout-table-wrapper {
  // select & input
  .row.mb-4 {
    font-family: $font-family-sans-serif;
    color: $greyscale400;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    flex-direction: row;
    margin-top: 20px;

    @media (max-width: $breakpoint-sm) {
      flex-direction: column;
    }

    .form-control {
      height: calc(2.5em + 0.75rem + 2px);
      border: 1px solid $greyscale100;
      border-radius: 16px;

      &:focus {
        box-shadow: none;
        border: 1px solid $greyscale200;
      }
    }

    .col.form-inline {
      display: none;
      select {
        height: calc(2.8em + 0.8rem + 2px);
        color: $greyscale400;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;

        @media (max-width: $breakpoint-sm) {
          margin: 10px 0 20px 0;
        }
      }
    }

    .col {
      max-width: 320px!important;
      min-width: 320px!important;

      .form-control {
        padding: 0 40px 0 15px!important;
      }
    }
  }
  // table
  .table-responsive.custom {
    font-family: $font-family-sans-serif;
    border: $greyscale200 1px solid;
    border-radius: 16px;
    overflow: auto;
    //min-width: 1500px;
    padding: 10px;

    .table.table-borderless.custom {
      background-color: white;

      // head
      .table-head-custom {
        color: $primary300;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;

        tr > th:not(:nth-child(2)) {
          text-align: center;
        }

        tr > th:nth-child(2) {
          padding-left: 55px;

          span > i {
            &::before {
              color: $greyscale300;
              font-size: 12px;
            }
          }
        }

        tr > th {
          line-height: 25px;
          padding-left: 40px;
        }
      }

      //body
      tbody {
        color: $greyscale400;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;

        tr > td:not(:nth-child(2)) {
          text-align: center;
        }

        tr > td:nth-child(2) {
          max-width: 160px;
          padding-left: 55px;
          width: 100px;
        }

        tr > td {
          line-height: 25px;
          padding-left: 40px;

          div > {
            span {
              color: white!important;
              font-size: 16px!important;
              font-style: normal!important;
              font-weight: 500!important;
            }
          }

          span {
            color: $greyscale400!important;
            font-style: normal!important;
            font-weight: 500!important;
            font-size: 16px!important;
          }
        }
      }
    }
  }

  #layoutFoldersColumnDropdown {
    width: 70px;
  }

  // dropdown
  .layout-actions {
    display: flex;
    grid-gap: .75rem;

    .action {
      .combined-shape {
        transition: 0.2s;

        svg {
          width: 20px;
          height: 20px;
          path {
            transition: 0.2s;
            fill: $greyscale400;
          }
        }

        &:hover {
          svg {
            path {
              transition: 0.2s;
              fill: $secondary;
            }
          }
        }
      }

      .checked-actions {
        svg {
          width: 28px;
          height: 28px;
          stroke: $greyscale400;
        }

        &:hover {
          svg {
            transition: 0.2s;
            stroke: $secondary;
            fill: $secondary;
          }
        }
      }

      .pencil-alt {
        svg {
          width: 20px;
          height: 20px;
          stroke: $greyscale400;
        }

        &:hover {
          svg {
            transition: 0.2s;
            stroke: $secondary;
          }
        }
      }

      .ellipsis-vertical {
        svg {
          width: 20px;
          height: 20px;
          path {
            transition: 0.2s;
            fill: $greyscale400;
          }
        }

        &:hover {
          svg {
            path {
              transition: 0.2s;
              fill: $secondary;
            }
          }
        }
      }

      .document-duplicate {
        svg {
          width: 20px;
          height: 20px;
          path {
            transition: 0.2s;
            fill: $greyscale400;
          }
        }

        &:hover {
          svg {
            path {
              transition: 0.2s;
              fill: $secondary;
            }
          }
        }
      }
    }

    .btn.dropdown-item.action-menu {
      display: flex;
      line-height: 35px;
      color: $greyscale400;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;

      .trash {
        margin: 0 10px 2px 0;

        svg {
          width: 20px;
          height: 20px;
          path {
            transition: 0.2s;
            fill: $greyscale400;
          }
        }
      }

      .archive, .move-from-folder {
        margin: 0 10px 3px 2px;

        svg {
          width: 20px;
          height: 20px;
          path {
            transition: 0.2s;
            fill: $greyscale400;
          }
        }
      }

      .folder {
        margin: 0 6px 3px 0;

        svg {
          width: 25px;
          height: 25px;
          path {
            transition: 0.2s;
            fill: $greyscale400;
          }
        }
      }

      .restore {
        margin: 0 10px 3px 0;

        svg {
          width: 20px;
          height: 20px;
          path {
            transition: 0.2s;
            fill: $greyscale400;
          }
        }
      }

      .square-plus {
        margin: 0 10px 3px 0;

        svg {
          width: 20px;
          height: 20px;
          path {
            transition: 0.2s;
            fill: $greyscale400;
          }
        }
      }
    }
  }
  // fix for Popper: CSS warning
  .dropdown-menu {
    margin: 0!important;
  }

  .layout-actions.show {
    .ellipsis-vertical {
      svg {
        width: 20px;
        height: 20px;
        path {
          fill: $secondary;
        }
      }
    }

    .dropdown-menu.show {
      //transition: 0.2s;

      .dropdown-item {
        transition: 0.2s;
        &:hover {
          transition: 0.2s;
          color: $secondary;
          background-color: white;

          .document-duplicate {
            margin: 0 10px 1px 0;

            svg {
              width: 20px;
              height: 20px;
              path {
                transition: 0.2s;
                fill: $secondary;
              }
            }
          }

          .trash {
            margin: 0 10px 2px 0;

            svg {
              width: 20px;
              height: 20px;
              path {
                transition: 0.2s;
                fill: $secondary;
              }
            }
          }

          .square-plus {
            margin: 0 10px 3px 0;

            svg {
              width: 20px;
              height: 20px;
              path {
                transition: 0.2s;
                fill: $secondary;
              }
            }
          }
        }
      }
    }
  }

  // pagination
  .pagination {
    justify-content: center;
    margin: 0;

    .page-link {
      margin: 0 6px 0 6px;
      width: 38px;
      border-radius: 10px!important;
      border-color: transparent!important;
      text-align: center;
      font-weight: bold;
      white-space: nowrap;

      &:focus {
        box-shadow: none!important;
      }

      @media (max-width: $breakpoint-md) {
        width: 100%;
      }
    }

    .page-item.disabled {
      .page-link {
        background-color: $greyscale50;
        color: $greyscale200;
      }
    }

    .page-item.active.d-none.d-md-block {
      .page-link {
        background-color: $secondary;
        cursor: pointer;
        color: white;
        transition: 0.2s;

        &:hover {
          transition: 0.2s;
          background-color: darken($secondary, 5%);
        }
      }
    }

    .page-item.d-none.d-md-block {
      .page-link {
        background-color: $greyscale50;
        color: $primary700;

        transition: 0.2s;

        &:hover {
          transition: 0.2s;
          background-color: darken($greyscale50, 5%);
        }
      }
    }

    .page-item {
      .page-link {
        background-color: $greyscale50;
        color: $primary700;

        transition: 0.2s;

        &:hover {
          transition: 0.2s;
          background-color: darken($greyscale50, 5%);
        }
      }
    }
  }

  .col.text-right.text-muted {
    display: none;
  }

  .ms-parent {
    .ms-choice > span {
      top: 0.5em;
    }
  }
}

.layout-content-wrapper {
  border: $greyscale200 1px solid;
  border-radius: 16px;
  padding: 20px;
  font-family: $font-family-sans-serif;
  color: $greyscale400;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;

  .btn-primary, .btn-primary.dropdown-toggle {
    color: white;
    background-color: $primary200!important;
    border-color: $primary200;
    transition: 0.2s;
    height: 48px;
    border-radius: 8px;
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    
    &.on-hover{
      border-radius: 0;
    }
    
    &:hover {
      transition: 0.2s;
      background-color: $primary300!important;
      border-color: $primary300!important;
    }

    &:focus {
      box-shadow: none!important;
      border: 1px solid $primary200!important;
      background-color: $primary200!important;
    }

    &:active {
      box-shadow: none!important;
      border: 1px solid $primary200!important;
      background-color: $primary200!important;
    }

    &:disabled {
      box-shadow: none!important;
      border: 1px solid $primary200!important;
      background-color: $primary200!important;
      cursor: default!important;
    }
  }

  .dropdown-toggle {
    &::after {
      display: none!important;
    }
  }

  .lh34 {
    line-height: 34px!important;
  }

  .form-control, .ms-parent.form-control {
    height: calc(2.5em + 0.75rem + 2px);
    border: 1px solid $greyscale100;
    border-radius: 16px;
    font-family: $font-family-sans-serif;
    color: $greyscale400;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;

    &:focus {
      box-shadow: none;
      border: 1px solid $greyscale200;
    }
  }

  label {
    color: $greyscale300;
    font-weight: 700;
    font-size: 18px;
  }

  .select-wrapper {
    display: flex;
    flex-wrap: nowrap;

    @media (max-width: $breakpoint-md) {
      flex-wrap: wrap;
    }
  }
  .tags {
    display: flex;
    flex-direction: column;
    align-self: end;
    span.select2 {
      width: 100%;
    }
    .select2 {
      .select2-selection {
        border: 1px solid #e1e1e1;
        border-radius: 16px;
        min-height: calc(2.5em + 0.75rem + 2px);
        display: flex;
        align-items: center;
      }
      .select2-selection__choice {
        padding: 2px 0px 2px 5px!important;
        background-color: #40B883;
        border: none;
        color: white;
      }

      .select2-selection__choice__remove {
        position: inherit;
        float: right;
        border-right: none;
        border-radius: inherit;
        margin-left: 4px;
        color: #276C4D;
        font-size: 20px;
      }
    }
  }
  .select-buttons {
    display: flex;
    width: 100%;
    min-width: 633px;
    gap: .5rem;
      .tags-input {
          display: flex;
          align-items: center;
          margin-top: 27px;
          .bootstrap-tagsinput {
              border: unset !important;
              box-shadow: unset !important;
          }
      }
      .select2 {
          margin-top: 27px;
          .select2-selection {
              border: 1px solid #e1e1e1;
              border-radius: 16px;
              min-height: calc(2.5em + 0.75rem + 2px);
              display: flex;
              align-items: center;
          }
          .select2-selection__choice {
              padding: 0px 0px;
          }

          .select2-selection__choice__remove {
              position: inherit;
          }
      }

  }

  .select-template {
    width: 100%;

    label {
      font-weight: 700;
      font-size: 18px;
    }

    .ms-parent.form-control {
      .ms-choice {
        span {
          span {
            display: none !important;
          }
        }
      }

      .ms-drop.bottom {
        width: auto;
        left: 0;

        ul > li > label {
          font-weight: inherit;
          font-size: inherit;
        }

        ul > li > label.optgroup {
          font-weight: bold !important;
        }
      }
    }
  }

  .add-button {
    white-space: nowrap;
    align-self: end;

    .c-icon:not(.c-icon-c-s):not(.c-icon-custom-size) {
      color: white;
      width: 20px!important;
      height: 0!important;
      font-size: 12px!important;
    }

    .delete-new-grid-items {
      display: none;
      text-align: center;
      font-size: 12px;
      color: $greyscale300;
      text-decoration: none;
    }
  }
}

//edit page
.layout-edit-management {
  justify-content: flex-end;

  .management-action {
    margin: 0 0 20px 0;
  }
}

.layout-content-wrapper.edit-page {
  .layout-title-content {
    width: 100%;
    margin: 15px 0 25px 0;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
  }

  .name-input {
    label {
      font-weight: 700;
      font-size: 18px;
    }
  }

  .select-wrapper {
    display: flex;
    flex-wrap: nowrap;

    @media (max-width: $breakpoint-md) {
      flex-wrap: wrap;
    }
  }

  .select-template {
    width: 100%;

    label {
      font-weight: 700;
      font-size: 18px;
    }

    .ms-parent.form-control {

      .ms-drop.bottom {
        width: auto;
        left: 0;

        ul > li > label {
          font-weight: inherit;
          font-size: inherit;
        }
      }
    }
  }

  .add-button {
    @media (max-width: $breakpoint-md) {
      width: 85px;
    }

    .c-icon:not(.c-icon-c-s):not(.c-icon-custom-size) {
      color: white;
      width: 20px!important;
      height: 0!important;
      font-size: 12px!important;
    }
  }

  .control-buttons {
    display: flex;
    justify-content: flex-end;
    flex-wrap: nowrap;

    @media (max-width: $breakpoint-md) {
      justify-content: center;
      flex-wrap: wrap;
    }

    .toggle-button {
      display: block;
      justify-content: center;
      min-width: 186px;
      margin: 0 5px 5px 0;

      .toggle-label {
        font-weight: 700;
        font-size: 18px;
        margin: 0 0 0 0;
      }
    }
  }

  .template-counts-container{
    display: flex;
    align-items: center;

    .plus-btn{
      margin-right: 10px;
      font-size: 20px;
    }

    .minus-btn{
      margin-left: 10px;
      font-size: 20px;
      margin-right: 10px;
    }

    .template-counts{
      width: 64px;
      padding: 0px;
      height: 32px;
      padding-left: 12px;
      padding-right: 12px;
      text-align: center;
    }
  }
}

//layout create
.layout-content-wrapper.create-page {
  .layout-title-content {
    width: 100%;
    margin: 15px 0 25px 0;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
  }

  .name-input {
    label {
      font-weight: 700;
      font-size: 18px;
    }
  }

  .select-wrapper {
    display: flex;
    flex-wrap: nowrap;

    @media (max-width: $breakpoint-md) {
      flex-wrap: wrap;
    }
  }

  .select-template {
    width: 100%;

    label {
      font-weight: 700;
      font-size: 18px;
    }

    .ms-parent.form-control {

      .ms-drop.bottom {
        width: auto;
        left: 0;

        ul > li > label {
          font-weight: inherit;
          font-size: inherit;
        }
      }
    }
  }

  .add-button {
    @media (max-width: $breakpoint-md) {
      width: 85px;
    }

    .c-icon:not(.c-icon-c-s):not(.c-icon-custom-size) {
      color: white;
      width: 20px!important;
      height: 0!important;
      font-size: 12px!important;
    }
  }

  .template-counts-container{
    display: flex;
    align-items: center;

    .plus-btn{
      margin-right: 10px;
      font-size: 20px;
    }

    .minus-btn{
      margin-left: 10px;
      font-size: 20px;
      margin-right: 10px;
    }

    .template-counts{
      width: 64px;
      padding: 0px;
      height: 32px;
      padding-left: 12px;
      padding-right: 12px;
      text-align: center;
    }
  }
}
//create-page footer
.create-page-footer {
  display: flex;
  justify-content: flex-end;
  padding: 0 35px 20px 0;

  .btn-primary {
    color: white;
    background-color: $primary200!important;
    border-color: $primary200;
    transition: 0.2s;
    height: 48px;
    border-radius: 8px;
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;

    &:hover {
      transition: 0.2s;
      background-color: $primary300!important;
      border-color: $primary300!important;
    }

    &:focus {
      box-shadow: none!important;
      border: 1px solid $primary200!important;
      background-color: $primary200!important;
    }

    &:active {
      box-shadow: none!important;
      border: 1px solid $primary200!important;
      background-color: $primary200!important;
    }

    &:disabled {
      box-shadow: none!important;
      border: 1px solid $primary200!important;
      background-color: $primary200!important;
      cursor: default!important;
    }
  }
}

//show or view page
.show-section-grid-wrapper {
  display: grid;
  grid-template-columns: auto max-content;
  grid-template-rows: auto max-content;
  width: 100%;

  .section-content {
    .section-body {
      padding: 20px;
      position: relative!important;
    }
  }

  .section-form {
    position: relative;

    .editor-form {
      display: grid;
      height: 100%;
      align-content: center;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: auto;
      grid-template-areas:
      "font-type font-type font-type font-type"
      "font-size font-size font-color font-color";
      gap: 10px;
      margin-right: 10px;

      .font-type {
        grid-area: font-type;
      }

      .font-size {
        grid-area: font-size;
      }

      .font-color {
        grid-area: font-color;
      }

      .form-control {
        height: calc(1.7em + 0.75rem + 2px);
        border: 1px solid $greyscale100;
        border-radius: 15px!important;

        font-family: $font-family-sans-serif;
        color: $greyscale400;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;

        &:focus {
          box-shadow: none;
          border: 1px solid $greyscale100;
        }
      }
    }

    .handle-resize-icon {
      position: absolute;
      top: 50%;
      left: -27px;
      transform: translateX(-50%) rotate(90deg);
      box-sizing: border-box;
      width: 43px;
      height: 21px;
      border: 1px solid $greyscale100;
      border-radius: 12px;
      background-image: url('/img/icons/resize-arrow-v.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-color: white;
      &:hover{
        cursor: col-resize;
      }
    }
  }

  #template-group-preview {
    .grid-stack-item {
      border: 1px solid $greyscale100;
    }

    canvas {
      max-width: 100%;
      height: auto;
    }
  }
  .save-action {
    position: relative;

    .btn-cancel-changes {
      padding-top: 0;
      padding-bottom: 0;
      position: absolute;
      font-size: 12px;
      bottom: -20px;
      left: 7px;
      color: $greyscale300;
      outline: none;
    }
  }
}

#text-editor-control {
  z-index: 1;
  width: min-content;

  label {
    font-family: $font-family-sans-serif;
    font-size: 14px;
    color: $greyscale400;
    font-weight: 500;
    line-height: 24px;
    cursor: inherit;
  }

    .actions-checkbox {
      margin-top: 2px;
      grid-area: action-checkbox;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 1;

      .form-check {
        margin-left: 0;
        display: flex;
        align-items: center;
        cursor: pointer;

        .form-check-label {
          display: inline-block;
          white-space: nowrap;
        }

        .form-check-input {
          position: static;
          margin: 0 2px 0 0;
          accent-color: $primary300;
          cursor: inherit;
        }
      }
    }

  .image-editor {
    display: flex;
    flex-direction: column;
    padding: 8px;
    .actions {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .file-image {
        min-width: 200px;
      }

      .button-custom {
        color: transparent !important;
        background-image: url(/img/icons/banner-file.svg);
        background-repeat: no-repeat;
        background-position: center;
        border: 1px solid #e1e1e1;
        border-radius: 15px;
        background-color: white !important;
        height: 38px;
      }
      .delete-image {
        color: transparent !important;
        border: none;
        border-radius: 50%;
        height: 28px;
        width: 28px;
        margin-left: 5px;
      }
    }

    &__footer {
      width: max-content;
      min-width: 100%;
      display: flex;
      justify-content: space-between;

      .apply-to-all,
      .auto-position {
        margin-top: 2px;
        margin-right: 5px;

        .form-check-input {
          margin-left: -17px!important;
          accent-color: $primary300;
        }
      }
    }


  }

  .container-text-running {
    width: 30px;
    height: 20px;
    overflow: hidden;
    position: relative;

    span {
      position: absolute;
      white-space: nowrap;
    }
  }

  #text-editor-dragable {
    position: absolute;
    left: 0;
    cursor: move;
    width: 100%;
    height: 2rem;
    background: #e1e1e1;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  #myTab, .mt-text-editor {
    margin-top: 2.3rem;
  }

  .apply-to-property-list {
    background-color: white;
    display: none;
    position: absolute;
    top: 63%;
    left: 18%;
    border-radius: 6px;
    div.form-check-input {
      margin: 0;
      .form-check-input {
        padding-left: 5px;
      }
      .form-check-label {
        padding-right: 5px
      }
    }

  }
}

.layout-content-wrapper.view-page {
  //border: transparent 1px solid;

  .control-buttons {
    display: flex;
    justify-content: center;
    width: auto;
    margin-bottom: 15px;
    flex-wrap: nowrap;

    .sub-control-buttons {
      display: flex;

      @media (max-width: $breakpoint-sm) {
        width: 100%;
        justify-content: center;
      }

      .btn-settings {
        align-self: center;
        margin-top: 15px;
      }
      .btn-preview.active {
        transition: 0.2s;
        background-color: $primary300 !important;
        border-color: $primary300 !important;
      }
    }

    .btn-mr {
      margin: 15px 15px 0 0;
    }

    @media (max-width: $breakpoint-sm) {
      flex-wrap: wrap;
      width: 100%;
    }
  }

  .download-icon {
    svg {
      width: 15px;
      height: 15px;
      path {
        fill: white;
      }
    }
  }

  .bookmark-icon {
    svg {
      width: 15px;
      height: 15px;
      path {
        fill: white;
      }
    }
  }

  .edit-icon {
    svg {
      width: 15px;
      height: 15px;
      stroke: white;
    }
  }

  .btn-mr [data-toggle-second="tooltip"] {
    @media (max-width: $breakpoint-xl) {
      font-size: 0;
      i {
        font-size: initial;
      }
    }
  }

  .grid-stack-row {
    position: relative;

    .actions-grid {
      display: flex;
      align-items: center;
      position: absolute;
      top: 5px;
      right: 5px;
      opacity: 0;
      transition: all .2s;
    }

    .select-image-dropdown,
    .settings-dropdown {
      .dropdown-toggle {
        height: 34.14px;
        border-radius: 50% !important;
        margin-right: 5px;
        border-color: $primary200 !important;
        svg {
          width: 16px;
          height: 17px;
        }
      }
      .dropdown-item {
        display: flex;
        .dropdown-item-icon {
          margin-right: 4px;
          svg path {
          fill: red !important;
          stroke: red !important;
          }
        }
      }
      .dropdown-submenu.on-hover {
        .dropdown-toggle.export-feed {
          border-radius: unset !important;
          border-color: white !important;
        }
        .dropdown-item-icon {
          margin-right: 8px;
        }
        .dropdown-toggle {
          font-size: 1rem;
        }
      }
    }

    &:hover .actions-grid {
      opacity: 1;
      z-index: 1;
    }
  }

  .template-list {
    .template-item {
      .template-item-stats {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        .template-item-stat {
          display: flex;
          flex-direction: row;
          align-items: center;
          span {
            font-weight: bold;
            color:#111;
          }
          .template-item-stat-badge {
            background-color: #ebebeb;
            padding: 8px 6px;
            border-radius: 6px;
          }
        }
      }
    }
    .template-item.is-dragging {
      .grid-stack-row .draggable-template {
        opacity: 1;
      }
    }
  }
}

//edit template page

//preview pop-up
.template-edit-preview-popup {
  top: 70px;
  right: 5px;
  z-index: 1040;
  position: fixed;
  //border: 1px solid $greyscale100;
  //border-radius: 12px;
  box-shadow: 2px 2px #888888;

  //header
  .template-edit-drag-handler {
    width: 100%;
    //padding: 10px!important;
    //border-bottom: 1px solid $greyscale100;
    cursor: move;
    border: 1px solid $greyscale100!important;
  }

  // canvas
  .template-canvas-wrapper {
    border: 1px solid $greyscale100!important;

    .canvas-container {
      border: none!important;
      box-sizing: content-box;
    }
  }

  //footer
  .template-edit-footer {
    border: 1px solid $greyscale100!important;
    //border-top: 1px solid $greyscale100!important;
    //border-left: none!important;
    //border-right: none!important;
    //border-bottom: none!important;
  }
}

//edit template page forms
.template-edit-form.layout-edit-template {
  background-color: $greyscale50;
  padding: 15px 30px 15px 30px;
  border-radius: 12px;
  width: calc(100% + 60px);
  margin-left: -30px;

  .btn.btn-primary {
    color: $primary300;
    background-color: transparent!important;
    border-color: $primary300;
    transition: 0.2s;
    border-radius: 8px;
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;

    &:hover {
      transition: 0.2s;
      border-color: $primary300!important;
    }

    &:focus {
      box-shadow: none!important;
      border: 1px solid $primary300!important;
      background-color: transparent!important;
    }

    &:active {
      box-shadow: none!important;
      border: 1px solid $primary300!important;
      background-color: transparent!important;
    }

    &:disabled {
      box-shadow: none!important;
      border: 1px solid $greyscale400!important;
      color: $greyscale400!important;
      background-color: transparent!important;
      cursor: default!important;
    }
  }

  .btn.btn-primary.select-bkimg {
    color: transparent!important;
    background-image: url('/img/icons/banner-file.svg');
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid $greyscale100;
    border-radius: 15px;
    background-color: white!important;

    &:hover {
      transition: 0.2s;
      border-color: $greyscale100!important;
      background-color: white!important;
      color: transparent!important;
    }

    &:focus {
      box-shadow: none!important;
      border: 1px solid $greyscale100!important;
      background-color: white!important;
      color: transparent!important;
    }

    &:active {
      box-shadow: none!important;
      border: 1px solid $greyscale100!important;
      background-color: white!important;
      color: transparent!important;
    }

    &:disabled {
      box-shadow: none!important;
      border: 1px solid $greyscale100!important;
      color: transparent!important;
      background-color: $greyscale100!important;
      cursor: default!important;
      background-image: url('/img/icons/banner-file.svg');
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .form-control {
    height: calc(1.7em + 0.75rem + 2px);
    border: 1px solid $greyscale100;
    border-radius: 15px!important;

    font-family: $font-family-sans-serif;
    color: $greyscale400;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;

    &:focus {
      box-shadow: none;
      border: 1px solid $greyscale200;
    }
  }
  div.input-group {
    input[type="number"] {
      border-left: 1px solid #b1b1b1 !important;
      border-top: 1px solid #b1b1b1 !important;
      border-bottom: 1px solid #b1b1b1 !important;
      border-right: none !important;
      border-bottom-left-radius: 15px !important;
      border-top-left-radius: 15px !important;
      border-bottom-right-radius: 0px !important;
      border-top-right-radius: 0px !important;
    }
    input[type="number"].both_sided {
      border-left: none !important;
      border-top: 1px solid #b1b1b1 !important;
      border-bottom: 1px solid #b1b1b1 !important;
      border-right: none !important;
      border-bottom-left-radius: 0px !important;
      border-top-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      border-top-right-radius: 0px !important;
    }
  }

  .form-group {
    label {
      font-family: $font-family-sans-serif;
      color: $greyscale400;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 17px;

      .badge.badge-success {
        color: white;
        font-size: 12px;
        padding: 0 5px;
      }
    }

    .select2-container .select2-selection--single {
      /*height: 38px !important;*/
      border-radius: 2px !important;
      border: 1px solid #ededed !important;
      font-size: 0.9em;
    }

    .select2-container .select2-selection--single:hover {
       background-color: #ebebeb !important;
    }

    .select2 {
      .select2-selection.select2-selection--single {
        /*border-radius: 15px!important;*/
        border-color: $greyscale100!important;
        border-radius: 2px !important;
        border: 1px solid #ededed !important;
        font-size: 0.9em;

        .select2-selection__rendered {
          color: $greyscale400;
          padding-left: 12px;
          font-size: 14px;
          /*
          div {
            line-height: 36px;
          }*/
        }
      }
    }
  }

  .form-row {
    label {
      font-family: $font-family-sans-serif;
      color: $greyscale400;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 17px;

      span {
        font-family: $font-family-sans-serif;
        color: $greyscale400;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;

        a {
          font-family: $font-family-sans-serif;
          color: $greyscale400;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          text-decoration: none;
        }
      }

      a {
        font-family: $font-family-sans-serif;
        color: $greyscale400;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        text-decoration: none;
        transition: 0.2s;

        &:hover {
          transition: 0.2s;
          color: $greyscale300;
        }
      }

      #view-img {
        font-family: $font-family-sans-serif;
        color: $greyscale400;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        text-decoration: none;
      }
    }

    .col-md-2.form-row.color-row {
      height: 86px;
    }
  }

  .input-group, .input-group.file-caption-main {
    .template-text-field.form-control {
      padding: 2px 35px 0 12px;
    }

    #text_headline_1_fontsize, .multiline-input {
      border-left: 1px solid $greyscale100 !important;
      border-top: 1px solid $greyscale100 !important;
      border-bottom: 1px solid $greyscale100 !important;
      border-right: none !important;
      border-radius: 15px 0 0 15px!important;
    }

    .input-group-append {
      #basic-addon2 {
        border-right: 1px solid $greyscale100 !important;
        border-top: 1px solid $greyscale100 !important;
        border-bottom: 1px solid $greyscale100 !important;
        border-left: none !important;
        border-radius: 0 15px 15px 0!important;
      }

      .input-group-text {
        label {
          font-size: 14px;
        }
      }
    }

    .file-caption-name.form-control, .file-caption.form-control.kv-fileinput-caption {
      border-left: 1px solid $greyscale100 !important;
      border-top: 1px solid $greyscale100 !important;
      border-bottom: 1px solid $greyscale100 !important;
      border-right: none !important;
      border-radius: 15px 0 0 15px!important;
      background-color: transparent;
    }

    .file-caption.form-control.kv-fileinput-caption {
      background-color: white;
      .file-caption-name {
        line-height: 25px;
      }
    }

    .input-group-btn.input-group-append {
      .btn.btn-default.btn-secondary.kv-hidden.fileinput-cancel.fileinput-cancel-button,
      .btn.btn-default.btn-secondary.fileinput-remove.fileinput-remove-button {
        height: 38px!important;
        border-top: 1px solid $greyscale100;
        border-bottom: 1px solid $greyscale100;
        background-color: transparent;
        color: $greyscale400!important;
        line-height: 26px;
        padding: 0 0 0 5px;

        .glyphicon.glyphicon-ban-circle, .glyphicon.glyphicon-trash {
          &::before {
            font-size: 13px!important;
            padding-left: 2px!important;
            color: $greyscale400!important;
          }
        }
      }

      .btn.btn-primary.btn-file {
        height: 38px!important;
      }
    }

    .input-group-btn.input-group-append {
      background-color: transparent;
      font-family: $font-family-sans-serif;
      color: #6c757d;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;

      .btn.btn-primary.btn-file {
        color: #6c757d;
        border-right: 1px solid $greyscale100 !important;
        border-top: 1px solid $greyscale100 !important;
        border-bottom: 1px solid $greyscale100 !important;
        border-left: none !important;
        border-radius: 0 15px 15px 0!important;
      }

      .btn.btn-default.btn-outline-secondary {
        background-color: transparent;
        border-top: 1px solid $greyscale100 !important;
        border-bottom: 1px solid $greyscale100 !important;

        color: #6c757d;
        font-style: normal;
        font-size: 16px;

        &:focus {
          box-shadow: none!important;
          background-color: transparent!important;
        }
      }
    }
  }
}
//1lx
form#layoutForm {
  .selected-image {
    width: fit-content;
    top: unset;
    left: 97px;
    bottom: 4%;
    &:hover > div.remove-selecter-image {
        display: flex;
    }
    div.remove-selecter-image {
      display: none;
      justify-content: center;
      align-items: center;
      width: 17px;
      height: 17px;
      position: absolute;
      top: -7px;
      right: -7px;
      background-color: #b1b1b1;
      border-radius: 50%;
      cursor: pointer;
      &::before {
        content: '\f00d';
        color: #fff;
        font-family: "Font Awesome 5 Free";
        font-size: 11px;
        font-weight: 700;
        margin-top: 2px;
        margin-left: -1px;
      }
      &:hover {
        background-color: #6c757d;
      }
      &[data-value=""]{
        display: none;
      }
    }

    .image-preview-item {
      display: flex;
      img {
        width: 100%;
        object-fit: cover;
      }
      &:hover > div.remove-selecter-image {
        display: flex;
        z-index: 1;
      }
    }
  }
  .background-multiple .selected-image {
    position: static;
  }
  textarea.multi-line-text {
    padding-top: .5rem;
    padding-right: 1rem;
    scrollbar-color: $greyscale200 transparent;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: $greyscale200;
      border-radius: 10px;
    }
  }
  button.multi-line-text-clear-button {
    position: absolute;
    margin-left: 0 !important;
    top: .2rem;
    right: .5rem;
    margin-left: 0;
    height: fit-content;
    width: fit-content;
    padding: 0 !important;
  }
}

.image-editor-actions {
  display: flex;
  grid-gap: 0.5rem;
  
  &--bg-actions {
    flex-wrap: wrap;
    gap: 5px;
    max-width: 180px;
  }

  button {
    border: none;
    background: none;
    outline: none;
  }

  img {
    width: 20px;
  }
}

.color-picker-input {
  min-width: 4rem;
}

#generateImageModal button.btn-primary, #productDescriptionsModal button.btn-primary{
  color: white;
  background-color: #209c86 !important;
  border-color: #209c86;
}

img.cropper-hide {
    display:none;
}

.btn-primary-itsrapid, .btn-secondary-itsrapid {
    svg, svg path {
      fill: white !important;
    }
    padding:13px 10px !important;
    transition: 0.2s !important;;
    height: 48px !important;;
    border-radius: 8px !important;;
    font-family: "Nunito", sans-serif !important;;
    font-style: normal !important;;
    font-weight: 700 !important;
    font-size: 14px !important;;
}

.btn-primary-itsrapid {
  color: white !important;
  background-color: #209c86 !important;
  border-color: #209c86 !important;
}

.btn-secondary-itsrapid {
  color: white !important;
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}

.icon-swap {
  background-color: transparent !important;
  background-image: url('/img/icons/swap.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 1.75rem;
  height: 1.75rem;
}

div.layout-green-selects{

  button.ms-choice{
    position: relative;
    background-color: #007964 !important;
    border-color: #007964 !important;
    border-radius: inherit;
  }

  button.ms-choice span{
    color: white !important;
    font-weight: 700 !important;
  }

  div.ms-parent{
    background-color: #007964 !important;
    border-color: #007964 !important;
  }

  div.icon-caret{
    border-color: #FFF transparent transparent transparent !important;
  }

}

.copy-hex-color {
  color: unset !important;
  text-decoration: none !important;

  .cil-copy {
    width: 1.3rem;
    height: 1.3rem;
    font-size: 1.3rem;
  }
}

.toggle-object-lock {
  position: absolute;
  right: .5rem;
  top: .5rem;

  text-decoration: none !important;
  color: unset !important;

  &[data-lock=false] {
    .cil-lock-locked {
      display: none;
    }
  }

  &[data-lock=true] {
    .cil-lock-unlocked {
      display: none;
    }
  }
}

.text-editor-icon {
  color: inherit;
  position: absolute;
  top: 0.5rem;
  text-decoration: none !important;
  margin-top: 1px;

  &:hover{
    cursor: pointer;
  }

  &.delete-text-field-icon {
    color: red;
    right: 2rem;

    &-mr {
      right: 3.6rem;
    }
  }

  &.pin-text-editor-modal-position-off {
    right: 2rem;
    font-size: 15px;
    margin: 0;
  }

  &.pin-text-editor-modal-position-on {
    right: 2rem;
    font-size: 15px;
    display: none;
  }
}

.show-section-grid-wrapper{
  .update-layout-btn {
    margin: 0 5px;
  }
  .toggle{
    border: 1px solid #adadad !important;
    font-size: 0.9em;
  }
  .btn-default.active , .toggle-off{
    color: #333 !important;
    background-color: #e6e6e6 !important;
    -webkit-box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
    box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
    line-height: 36px;
  }

  .toggle-on {
    line-height: 36px;
  }

  .toggle-handle{
    color: #333 !important;
    background-color: #fff !important;
    border-color: #ccc !important;
  }
}

.split.vertical {
  display: flex;
  flex-direction: row;

  #split-0 {
    height: calc(100vh - 185px);
    overflow: scroll;

    #layoutForm {
      margin: 0;
      width: 100%;
    }
  }

  #split-1 {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;

    .preview-popup-wrapper {
      display: block;
      position: relative;
    }

    #preview-popup {
      position: relative !important;
      top: 0 !important;
      left: 0 !important;
      z-index: 0 !important;
    }

    #drag-handler {
      cursor: default;
    }
  }

  .form-group {
    width: 100% !important;
    max-width: 100% !important;
    flex-basis: 100% !important;

    &.col-auto {
      width: auto !important;
      flex-basis: auto !important;
    }

    &.col-4 {
      max-width: 33.333333% !important;
      flex-basis: 33.333333% !important;
    }

    &.col-6 {
      max-width: 50% !important;
      flex-basis: 50% !important;
    }
  }
}

.gutter {
  background-color: #eee;
  background-repeat: no-repeat;
  background-position: 50%;
}

.gutter.gutter-horizontal {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
  cursor: col-resize;
}

.active-obj {
  label {
    font-weight: bold !important;

    & > * {
      font-weight: bold !important;
    }
  }

  box-shadow: 0 20px 10px -20px $primary100;
}

.dropdown-item.bg-theme-image {
  img {
    width: 30px;
    height: 20px;
    object-fit: contain;
  }

  span {
    margin-left: 5px
  }

  &.active {
    background-color: $primary200;
    color: white;
  }
}

.swal2-container {
  z-index: 1100;
}

.folders-modal {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: $greyscale400 !important;
  font-family: $font-family-sans-serif;

  .modal-content {
    padding: 23px 6px;
    font-size: 1rem;
    background-color: white !important;

    .modal-content-inner {
      padding: 0 !important;
    }

    input:not(.share-proof-url), select:not(.share-proof-url) {
      font-family: "Nunito", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #464646;
      width: 100%;
      height: 35px;
      background: white;
      border: 1px solid #b1b1b1;
      border-radius: 12px;
      padding: 0 8px 0 8px;
    }

    .bootstrap-tagsinput {
      input {
        width: auto !important;
        border: none !important;
        -webkit-border-radius: 0px !important;
        -moz-border-radius: 0px !important;
        border-radius: 0px !important;
      }
    }
  }

  .modal-header {
    padding: 0 10px;
    border: none;
    font-size: 20px;
    font-weight: 700;

    button {
      padding: 15px 15px 0 0;
    }
  }

  .modal-footer {
    padding: 0 10px;
    border: none;

    button {
      font-family: $font-family-sans-serif;
      padding: 5px 10px 5px 10px;
      font-size: 18px;
      line-height: 1.4;
      border-radius: 8px;
      border: 1px solid #209c86;

      &.btn-primary {
        background-color: $primary200;
        color: white;
      }

      &.btn-secondary {
        background-color: white;
        color: $primary200;
      }
    }
  }
}

.tooltip {
  .tooltip-inner {
    max-width: 900px !important;
  }
}

.dropdown-submenu.on-hover {
  position: relative;
}

.dropdown-submenu.on-hover .dropdown-menu.export-feed {
  top: 0;
  left: -251px;
  margin-top: -5px;
}

.dropdown-submenu.on-hover .dropdown-menu.download-ul-feed {
  top: 5px;
  left: -158px;
  margin-top: -5px;
}

.table-view-container {
  display: none;
}

.thumbnail-view {
  &-container {
    display: none;
  }
  
  &-controls {
    display: flex;
    justify-content: space-between;
    
    &__wrapper {
      display: flex;
    }
    
    &__search {
      max-width: 201px;
    }
    
    &__page-quantity {
      &.form-control {
        width: auto;
        display: inline-block;
      }
    }
  }
  
  &__layout-list {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin: 15px 0;

    .layout-item {
      border: 1px solid #e4e4e4;
      border-radius: 5px;
      padding: 10px 5px;
      position: relative;
      width: calc(20% - 12px);

      &.selected .layout-item__checkbox {
        display: block;
      }

      &:hover .layout-item__checkbox {
        display: block;
      }

      .layout-item__checkbox {
        display: none;
        position: absolute;

        input {
          display: none;
        }

        label {
          display: inline-block;
          width: 24px;
          height: 24px;
          background-color: #fff;
          border: 2px solid #209c86;
          border-radius: 4px;
          cursor: pointer;
          position: relative;
          transition: background-color 0.3s ease, border-color 0.3s ease;
          z-index: 10;

          i.fa-check {
            display: none;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 16px;
          }
        }

        input[type="checkbox"]:checked + label {
          background-color: #209c86;
        }

        input[type="checkbox"]:checked + label i.fa-check {
          color: #fff;
          display: block;
        }
      }

      &__image {
        display: block;
        position: relative;
        padding-top: 100%;
        overflow: hidden;
        margin-bottom: 10px;

        img {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: contain;
        }
      }

      &__company-name {
        display: flex;
        justify-content: space-between;
      }

      .dropdown-item {
        column-gap: 5px;

        &--view {
          display: flex;
          column-gap: 5px;
          padding: 4px 24px;
        }

        &--catalog {
          display: flex;
          column-gap: 5px;
          padding: 4px 24px 4px 19px;
        }
      }

      .dropdown-icon {
        margin: 0 !important;

        &--copy svg {
          width: 23px;
        }

        &--edit svg {
          width: 24px !important;
          height: 24px !important;
        }

        &--archive svg {
          width: 26px;
          height: 26px;
        }

        &--assign svg {
          width: 24px;
          height: 22px;
        }

        &--view svg {
          fill: red;
          width: 22px;
          height: 22px;

          path {
            fill: #464646;
          }
        }
      }

      .ellipsis-vertical {
        &:hover {
          svg {
            path {
              transition: 0.2s;
              fill: $secondary;
            }
          }
        }
      }
    }

    .layout-item:hover, .selected {
      outline: 3px solid $primary200;
    }

    @media (max-width: $breakpoint-lg) {
      .layout-item {
        width: calc(25% - 12px);
      }
    }
  }
}

.toggle-favorite {
  opacity: 0;
  position: absolute;
  top: .25em;
  right: .25em;

  .is-favorite & {
    .far {
      font-weight: 900;
    }
  }

  :hover > & {
    opacity: 1;
  }
}

.highlight-favorite {
  position: relative;
}

.is-favorite {
  &.highlight-favorite {
    border-color: $primary100;
  }
}

.ph--folder-open-thin {
  display: inline-block;
  width: 26px;
  height: 22px;
  --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256'%3E%3Cpath fill='%23000' d='M241.72 113a11.88 11.88 0 0 0-9.73-5H212V88a12 12 0 0 0-12-12h-70.67l-28.8-21.6a12.05 12.05 0 0 0-7.2-2.4H40a12 12 0 0 0-12 12v144a4 4 0 0 0 4 4h179.09a4 4 0 0 0 3.79-2.74l28.49-85.47a11.86 11.86 0 0 0-1.65-10.79M40 60h53.33a4 4 0 0 1 2.4.8l29.87 22.4a4 4 0 0 0 2.4.8h72a4 4 0 0 1 4 4v20H69.76a12 12 0 0 0-11.38 8.21L36 183.35V64a4 4 0 0 1 4-4m195.78 61.26L208.2 204H37.55L66 118.74a4 4 0 0 1 3.76-2.74H232a4 4 0 0 1 3.79 5.26Z'/%3E%3C/svg%3E");
  background-color: currentColor;
  -webkit-mask-image: var(--svg);
  mask-image: var(--svg);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}

.canvas-object-rotate-tooltip {
  display: none;
  background-color: #000000;
  position: absolute;
  z-index: 10000;
  left: 657px;
  top: 442px;
  width: 35px;
  height: 20px;
  font-size: 12px;
  padding: 2px;
  border-radius: 5px;
  color: white;
  text-align: center;
}

.select-template img {
  width: 2em;
  height: 2em;
  object-fit: contain;
  margin: 0 .5rem;
}

// dropdown
.catalogs-actions {
  display: flex;
  grid-gap: .75rem;

  .action {

    .combined-shape {
      transition: 0.2s;
      pointer-events:none;

      svg {
        width: 20px;
        height: 20px;
        path {
          transition: 0.2s;
          fill: $greyscale400;
        }
      }

      &:hover {
        svg {
          path {
            transition: 0.2s;
            fill: $secondary;
          }
        }
      }
    }

    .dropdown-menu.show {
      z-index: 1100;
    }

    .checked-actions {
      svg {
        width: 28px;
        height: 28px;
        stroke: $greyscale400;
      }

      &:hover {
        svg {
          transition: 0.2s;
          stroke: $secondary;
          fill: $secondary;
        }
      }
    }

    .pencil-alt {
      svg {
        width: 20px;
        height: 20px;
        stroke: $greyscale400;
      }

      &:hover {
        svg {
          transition: 0.2s;
          stroke: $secondary;
        }
      }
    }

    .ellipsis-vertical {
      svg {
        width: 20px;
        height: 20px;
        path {
          transition: 0.2s;
          fill: $greyscale400;
        }
      }

      &:hover {
        svg {
          path {
            transition: 0.2s;
            fill: $secondary;
          }
        }
      }
    }

    .document-duplicate {
      svg {
        width: 20px;
        height: 20px;
        path {
          transition: 0.2s;
          fill: $greyscale400;
        }
      }

      &:hover {
        svg {
          path {
            transition: 0.2s;
            fill: $secondary;
          }
        }
      }
    }
  }
  .trash, .square-plus {
    margin: 0 10px 2px 0;

    svg {
      width: 20px;
      height: 20px;

      path {
        transition: 0.2s;
        fill: $greyscale400;
      }
    }
  }

}

