@import "variables";

.section-header {
  padding: 0 0 0 40px;

  @media (max-width: $breakpoint-sm) {
    padding: 0 0 0 0;
  }

  .header {
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    color: $primary700;
  }

  .breadcrumb {
    //background-color: $greyscale50;
    background-color: transparent;
    padding: 12px 0 0 20px;

    @media (max-width: $breakpoint-sm) {
      padding: 12px 0 0 0;
    }

    .home-link {
      line-height: 1rem;

      .home {
        svg {
          width: 17px;
          height: 17px;

          path {
            fill: $primary200;
          }
        }
      }
    }

    .bi.bi-chevron-right {
      font-size: 14px;
      line-height: 24px;
      margin: 0 7px 0 7px;
      color: $primary200;
    }

    .breadcrumb-item {
      font-family: $font-family-sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: $greyscale300;

      @media (max-width: $breakpoint-sm) {
        font-size: 12px;
      }

      a {
        color: $greyscale300;

        &:hover {
          text-decoration: none;
          color: $greyscale400;
        }
      }
    }

    .breadcrumb-item.active {
      color: $greyscale400;
    }

    .breadcrumb-item + .breadcrumb-item::before {
      display: none;
    }

    .breadcrumb-item + .breadcrumb-item {
      padding: 0;
    }
  }
}

.section-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  word-wrap: break-word;
  background-color: white;
  background-clip: border-box;
  border: 1px solid $greyscale100;
  border-radius: 16px;
  margin: 15px;
  
  .section-body {
    padding: 20px;

    .section-management {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      flex-direction: row;

      .management-link {
        font-family: $font-family-sans-serif;
        color: $greyscale400;
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        transition: 0.2s;
        text-decoration: none;

        &:hover {
          transition: 0.2s;
          color: $greyscale300;
        }

        i {
          font-size: 13px;
        }
      }
    }
  }
}
