@import "variables";
@import "~@coreui/coreui/scss/coreui";

.c-sidebar {
  z-index: 5 !important;

  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: #66968e;
  }
  
  .ps__rail-y {
    opacity: 1;
    left: 242px;
    background: transparent;

    .ps__thumb-y {
      opacity: 1;
      width: 6px;
      background: #66968e;
    }
    
    &:hover {
      opacity: 1;
      
      .ps__thumb-y {
        opacity: 1;
        width: 6px;
        background: #66968e;
      }
    }
  }
  
  .ps {
    &:hover {
      .ps__rail-y {
        opacity: 1;
      }
    }
  }
  
  font-family: $font-family-sans-serif;
  text-transform: capitalize;
  font-weight: 700;
  font-size: 16px;
  color: white;
  background: $primary300;
  
  .c-sidebar-brand {
    color: white;
    background: inherit;

    .sidebar-logo {}
  }

  .c-sidebar-nav-title {
    font-weight: 700;
    font-size: 16px;
    text-transform: capitalize;
    color: $greyscale100;
  }

  .c-sidebar-nav-dropdown-toggle {
    color: white;
    
    &:hover {
      background: darken($primary300, 0%);
    }
  }

  .c-sidebar-nav-link {
    color: white;
  }
  
  .c-sidebar-nav-dropdown.c-show {
    .c-sidebar-nav-dropdown-toggle {
      color: white;

      div > svg {
        stroke: $secondary;
        transition: 0.2s;
      }
      
      &:hover {
        color: $secondary;
        div > svg {
          stroke: $secondary;
          transition: 0.2s;
        }
      }
    }
    
    background: $primary300;
    
    .c-sidebar-nav-dropdown-items {
      overflow-y: unset;
    }
  }

  .c-sidebar-nav-dropdown {
    .c-sidebar-nav-dropdown-toggle {
      color: white;

      div > svg {
        stroke: white;
        transition: 0.2s;
      }

      &:hover {
        color: $secondary;
        div > svg {
          stroke: $secondary;
          transition: 0.2s;
        }
      }
    }

    background: $primary300;
  }

  .c-sidebar-nav-item {
    .c-sidebar-nav-link {
      color: white;
      background: $primary300;

      div > svg {
        stroke: white;
        transition: 0.2s;
      }

      &:hover {
        color: $secondary;
        div > svg {
          stroke: $secondary;
          transition: 0.2s;
        }
      }
    }
  }

  .c-sidebar-nav-link.c-active {
    color: $secondary!important;
    background: transparent;

    div > svg {
      stroke: $secondary;
    }
    
    &:hover {
      background: darken($primary300, 0%);
    }
  }
}
// minimized
.c-sidebar.c-sidebar-minimized {
  .c-sidebar-brand {
    color: white;
    background: inherit;
    rotate: -90deg;
    position: relative;
    
    @media (max-height: $breakpoint-sm) {
      display: none;
    }

    .sidebar-logo {}
  }

  .c-sidebar-nav-title {
    display: none;
  }
  
  .c-sidebar-nav {
    padding: 0;
    position: relative;
    list-style: none;

    @media (max-height: $breakpoint-sm) {
      top: 0;
    }
  }
  
  .c-sidebar-nav-dropdown {
    background: $primary300;
    
    &:hover {
      background: $primary300!important;
    }
    
    .c-sidebar-nav-dropdown-items {
      background: $primary300!important;
    }
  }

  .c-sidebar-nav-item:hover > .c-sidebar-nav-link {
    background: $primary300;
  }
}
// svg wrapper
.sidebar-svg-icon {
  margin: 0 14px 6px 0;

  &__help_support {
    margin: 0 14px 3px 0;
  }
  
  &__settings {
    margin: 0 14px 2px 0;
  }
  
  &__logout {
    margin: 0 14px 3px 2px;
  }
}

.c-sidebar-nav-icon {
  font-size: 28px;
  line-height: 15px;
  color: #fff !important;
  padding: 0 0 0 1px;
}