#app.c-body .c-main .container-fluid .box-banner.welmart,
#app.c-body .c-main.h .container-fluid .box-banner.welmart {
  
  #adForm {
    &>div:nth-child(3) {
      grid-template-columns: repeat(3, 1fr);
      &>div:first-child {
        grid-column: span 2;
      }
    }
    &>div:nth-last-child(3) {
      grid-template-columns: repeat(3, 1fr);
      &>div:first-child {
        grid-column: span 2;
      }
    }
  }
  .form-row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(125px, .333fr));
    margin-top: 0;
    &>div {
      min-width: 100%;
    }
    &>div.col-12 {
      grid-column: 1 / -1;
    }
  }
  label {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-height: 1.2em;
    max-width: 100%;
  }
  .box-button {
    padding-top: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(125px, .333fr));
    margin-top: 0;
    button {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-height: 38px;
      max-width: 100%;
      &[bg="fill"] {
        background-color: $primary200 !important;
        color: white !important;
        border: none !important;
      };
    }
  }

}

#app.c-body .c-main.v .container-fluid .box-banner.welmart {
  .box-button {
    padding-top: 0;
    grid-template-columns: repeat(auto-fit, minmax(125px, .3fr));
  }
}
