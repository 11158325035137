.job-progress-bar {
  display: flex;
  width: 100%;
  
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 100px;
    font-size: 10px;
    font-weight: 700;
    padding: 3px 0;
    position: relative;

    p {
      margin-bottom: 0;
    };
  }

  &__arrow {
    position: absolute;
    top: 0;
    right: -38%;
    z-index: 1;
    border-right: 18px solid transparent;
    border-bottom: 18px solid transparent;
    border-top: 18px solid transparent;
    box-sizing: border-box;
  }
}

.progress-bar-toggle {
  label {
    font-size: 10px;
    line-height: 23px;
  }
}

#product-description--import-table {
  .progress {
    width: 100%;
    max-width: 200px;
  }
}
