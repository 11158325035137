.theme-picker {
	position: relative;

	.theme-color {
		position: absolute;
		top: 9px;
		right: 25px;
		
		.circle-color {
			width: 15px;
			height: 20px;
			display: inline-block;
    		margin-right: 3px;
		}
	}
}

.circle-text-color-picker {
	position: relative;

	.circle-color {
		width: 26px;
		height: 26px;
		display: inline-block;
		position: absolute;
		top: 6px;
		right: 25px;
		border-radius: 50%;

		.text-color {
			display: inline-block;
			position: absolute;

			&#text_color1 {
				width: 11.5px;
				height: 2px;
				top: 7px;
				right: 7px;
			}

			&#text_color2 {
				width: 19.5px;
				height: 5px;
				top: 10px;
				right: 3px;
			}

			&#text_color3 {
				width: 11px;
				height: 2px;
				top: 19px;
				right: 7px;
			}
		}
	}
}

.selected-image {
	position: absolute;
    top: 33.5px;
    right: 8px;

	.background-preview {
		max-width: 64px;
		max-height: 32px;
	}
}

#selectBkImgModal {
	z-index: 1100;

	.background-image-grid {
		.grid-item {
            width: 30%;
			height: 150px;
			padding-bottom: 0;
			cursor: pointer;
            margin: 10px;
			img {
				object-fit: contain;
			}
		}
	}
	
	.full-size-image img {
		max-width: 100%;
	}
  .full-size-image {
    .hue-saturation-properties {
      .container {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 7px;
      }
      .color-spectrum {
        width: 300px;
        height: 30px;
        border: 1px solid #ccc;
        background: linear-gradient(to right, hsl(0, 100%, 50%), hsl(60, 100%, 50%), hsl(120, 100%, 50%), hsl(180, 100%, 50%), hsl(240, 100%, 50%), hsl(300, 100%, 50%), hsl(360, 100%, 50%));
        position: relative;
      }

      .color-spectrum input[type="range"] {
        width: 100%;
        height: 30px;
        opacity: 0;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
      }

      input[type="text"] {
        width: 100px;
      }
    }
	.hue-saturation-actions {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
  }
}

#toggleOffsetAngle {
	cursor: pointer;
	display: inline-block;
}

.offsetAngle-wrapper {
	display: none;
}
.burst-color-picker {
	position: relative;
	.burst-circle-color {
		width: 26px;
		height: 26px;
		display: inline-block;
		position: absolute;
		top: 6px;
		right: 25px;
		border-radius: 50%;
	
		.burst-text-color {
			display: inline-block;
			position: absolute;
			width: 16px;
			height: 2px;
			right: 5px;
	
			&#burst_text_color1 {
				top: 7px;
			}
			&#burst_text_color2 {
				top: 12px;
			}
			&#burst_text_color3 {
				top: 17px;
			}
		}
	}
}
