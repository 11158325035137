@import 'variables';

.canvas-ruler {
  position: absolute;
  user-select: none;
  
  &--horizontal {
    top: 0;
    left: 20px;
    width: calc(100% - 20px);
    height: 20px;
    border-left: 1px solid $greyscale100;
    border-bottom: 1px solid $greyscale100;
  }

  &--vertical {
    top: 20px;
    left: 0;
    width: 20px;
    height: calc(100% - 20px);
    border-top: 1px solid $greyscale100;
    border-right: 1px solid $greyscale100;
  }
  
  &__numbers {
    display: inline-block;
    position: absolute;
    font-size: 11px;
    width: 100%;
    
    &--horizontal {
      bottom: 0;
      padding: 0 5px;

      &::after {
        position: absolute;
        content: '';
        height: 100%;
        bottom: 0;
        right: 0;
        border-right: 1px solid $greyscale100;
      }
    }

    &--vertical {
      bottom: 0;
      padding: 5px 0;

      &::after {
        position: absolute;
        content: '';
        width: 100%;
        bottom: 0;
        right: 0;
        border-bottom: 1px solid $greyscale100;
      }
      
      &:first-child {
        .canvas-ruler__value--vertical {
          top: -9px;
        }
      }
    }
  }
  
  &__value {
    &--vertical {
      position: absolute;
      transform-origin: bottom center;
      top: 0;
      left: 100%;
      transform: rotate(-90deg);
      translate: -50% 0;
    }
  }
  
  &__unit {
    position: absolute;
    z-index: -1;
    
    &--horizontal {
      bottom: 0;
      height: 40%;
      border-right: 1px solid $greyscale100;
    }

    &--vertical {
      right: 0;
      width: 40%;
      border-bottom: 1px solid $greyscale100;
    }
  }
  
  &__tooltip {
    .tooltip-inner {
      color: red !important;
    }
  }
}
