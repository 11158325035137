.document-duplicate {
  svg {
    width: 20px;
    height: 20px;
    path {
      transition: 0.2s;
      fill: $white;
    }
  }
}
.grid-view {
  display: grid;
  &.cols-4 {
    grid-template-columns: repeat(4, 25%);
  }
  &.cols-2 {
    grid-template-columns: repeat(2, 50%);
  }
  align-items: center;
  justify-content: start;
  gap: 10px;
}

.custom-tooltip {
  position: relative;
  padding: 0.25rem;
  .custom-tooltip-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .custom-tooltip-container {
    display: none;
    position: absolute;
    padding-top: 2.25rem;
    z-index: 9999;
    .custom-tooltip-content {
      width: 12.5rem;
      padding: 0.5rem;
      border-radius: 0.5rem;
      a {
        color: white;
      }
    }
  }
  &:hover .custom-tooltip-container {
    display: block;
    top: 0;
    left: -5.375rem;
  }
}
.additional-fields {
  .additional-fields-show-page-dropdown {
    padding: 0;
    background-color: white;
    display: none;
    width: fit-content;
    height: fit-content;
    z-index: 1;
    position: absolute;
    left: -115%;
    top: 30px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    .additional-fields-dropdown-list {
      padding: 0;
      list-style-type:none;
      margin-block-start: 0;
      margin-block-end: 0;
      margin-inline-start: 0;
      margin-inline-end: 0;
      padding-inline-start: 0;
      li {
        padding: 12px 16px;
        &:hover {
          color: #16181b;
          text-decoration: none;
          background-color: #e9ecef;
        }
      }
    }
  }
  .bi-chevron-up {
    display: none;
  }
  .bi-chevron-down {
    display: inline-block;
  }
  &:hover {
    .additional-fields-show-page-dropdown {
      display: block;
    }
    .bi-chevron-up {
      display: inline-block;
    }
    .bi-chevron-down {
      display: none;
    }
  }
}

.mlt-fields {
  .mlt-fields-show-page-dropdown {
    padding: 0;
    background-color: white;
    display: none;
    width: fit-content;
    height: fit-content;
    z-index: 1;
    position: absolute;
    left: -136px;
    top: 0px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);

    .mlt-fields-dropdown-list {
      padding: 0;
      list-style-type: none;
      margin-block-start: 0;
      margin-block-end: 0;
      margin-inline-start: 0;
      margin-inline-end: 0;
      padding-inline-start: 0;

      li {
        padding: 12px 16px;
        width: 141px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
          color: #16181b;
          text-decoration: none;
          background-color: #e9ecef;
        }
      }
    }
  }

  &:hover {
    .mlt-fields-show-page-dropdown {
      display: block;
    }
  }
}
.icon-bulk-update {
  background-image: url("/images/bulk-update.svg");
  background-position: center center;
  background-size: cover;
}
.settings-dropdown {
  .rapid-copy {
    .slider {
     /*left: 106px;
      right: -106px;*/
    }
  }
}
.action-menu {
  display: flex;
  align-items: center;
  margin-right: 20px;
  
  .switch {
    position: relative;
    display: inline-block;
    margin: 0;

    > * {
      height: 17px;
    }
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 5px;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: #209c86;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #209c86;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 17px;
    width: 30px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .slider-products {
    /*left: 306px !important;
    right: -306px !important;*/
  }

  .text {
    color: #464646;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
  }
}

#edit-size-modal {
  .modal-dialog {
    .modal-content {
      .modal-body {
        .dimension {
          display: flex;
          border: 1px solid #00a3ba;
          background-color: #00a3ba;
          color: white;
          font-size: 14px;
          font-family: Nunito, sans-serif;
          font-weight: 500;
          border-radius: 15px;
          padding: 3px;
          align-items: center;
          cursor: pointer;
          .unselect-dimension {
            position: relative;
            top: -1px;
            font-size: 10px;
            color: red;
            padding: 1px 4px;
            margin: 0px 0px 0px 0px;
          }
        }
        .select-wrapper {
          width: 100%;
          .select-buttons {
            display: inline-flex;
            width: 100%;
            .select-template {
              
              width: 56%;
              label {
                margin: 0;
              }
              .form-control, .ms-parent.form-control {
                
                height: calc(2.5em + 0.75rem + 2px);
                border: 1px solid $greyscale100;
                border-radius: 16px;
                font-family: $font-family-sans-serif;
                color: $greyscale400;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;

                &:focus {
                  box-shadow: none;
                  border: 1px solid $greyscale200;
                }
                .ms-choice > span {
                  top: 6px;
                }
              }
            }
            .form-inline {
              width: 35%;
              .form-control, .ms-parent.form-control {
                height: calc(2.5em + 0.75rem + 2px);
                border: 1px solid $greyscale100;
                border-radius: 16px;
                font-family: $font-family-sans-serif;
                color: $greyscale400;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;

                &:focus {
                  box-shadow: none;
                  border: 1px solid $greyscale200;
                }
                .ms-choice > span {
                  top: 6px;
                }
              }
            }
            .add-button {
              width: 9%;
              .btn-primary, .btn-primary.dropdown-toggle {
                margin-top: 24px;
                color: white;
                background-color: $primary200!important;
                border-color: $primary200;
                transition: 0.2s;
                height: 50px;
                border-radius: 8px;
                font-family: $font-family-sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;

                &:hover {
                  transition: 0.2s;
                  background-color: $primary300!important;
                  border-color: $primary300!important;
                }

                &:focus {
                  box-shadow: none!important;
                  border: 1px solid $primary200!important;
                  background-color: $primary200!important;
                }

                &:active {
                  box-shadow: none!important;
                  border: 1px solid $primary200!important;
                  background-color: $primary200!important;
                }

                &:disabled {
                  box-shadow: none!important;
                  border: 1px solid $primary200!important;
                  background-color: $primary200!important;
                  cursor: default!important;
                }
              }
            }
          }
        }
      }
    }
  }
}



