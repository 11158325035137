.form-control {
  .ms-drop ul > li.hide-radio.selected {
    background-color: #209c86;
  }
}

.ms-drop ul > li label {
  overflow: hidden;
  text-overflow: ellipsis;
}
