//=============== new UI [START]=================>
div#layoutSettingsModal {
  border-radius: 12px;

  .nav {
    border-bottom: none;
    display: flex;
    gap: 14px;
    align-items: center;
    justify-content: flex-start;

    .nav-item {
      .nav-link {
        background-color: transparent;
        min-width: 79px;
        width: fit-content;
        padding: 8px 6px;
        font-weight: 700;
        font-size: 14px;

        &:hover {
          border-radius: 12px;
        }
      }

      .nav-link.active {
        background-color: #007964;
        color: #FFFFFF;
        border-radius: 12px;
      }
    }
  }

  .btn-primary {
    background-color: #209C86;
    color: white;
    border: none;
  }

  .form-row {
    align-items: center;

    .form-control {
      color: #727272;
      background-color: #FFFFFF;
      border-radius: 16px;
      border: 1px solid #E1E1E1;
    }

    .form-check {
      .form-check-input {
        width: 16px;
        height: 16px;
        border-radius: 4px;
        color: #E1E1E1;
        border: 1px solid #E1E1E1;
      }

      .form-check-label {
        font-weight: 600;
        color: #727272;
        margin-left: 4px;
        font-size: 14px;
      }
    }

    .form-group {
      .with-group-append {
        border-radius: 16px 0 0 16px!important;
      }

      .input-group-text {
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
      }
    }

    .file-input-group {
      .file-caption-name {
        border-radius: 16px 0 0 16px!important;
      }
      .btn-file {
        border-radius: 0 16px 16px 0!important;
      }
      .fileinput-cancel-button {
        padding: 6px 12px;
      }
    }

    label {
      color: #464646;
    }
  }

  .ms-choice > span {
    position: relative;
    top: -6px;
    left: -6px;
  }
}
//=============== new UI [END]=================>
