#app.c-body .c-main .container-fluid .box-banner.instagram,
#app.c-body .c-main.h .container-fluid .box-banner.instagram {
  .form-row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(125px, .3fr));
    margin-top: 0;
    &>div {
      min-width: 100%;
    }
    .span3 {
      
    }
    .span2 {

    }
  }
  .form-row.overloaded {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(125px, 100%));
    margin-top: 0;
    &>div {
      min-width: 100%;
    }
  }
  label {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-height: 1.2em;
    max-width: 100%;
  }
  .box-button {
    padding-top: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(125px, .3fr));
    margin-top: 0;
    button {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-height: 38px;
      max-width: 100%;
      &[bg="fill"] {
        background-color: $primary200 !important;
        color: white !important;
        border: none !important;
      };
    }
  }
  input[type=text].form-control,
  input[type=number].form-control {
      border-radius: 15px !important;
      border: 1px solid #e1e1e1;
      &::placeholder {
        color: $greyscale200;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.333;
      }
    }
  div.form-group {
    &>input[type=color],
    &>input[type=color]::-webkit-color-swatch,
    &>input[type=color]::-webkit-color-swatch-wrapper {
      border-radius: 4px !important;
      max-width: 40px;
      overflow: hidden;
    }

  }
}

#app.c-body .c-main.v .container-fluid .box-banner.instagram {
  .form-row {
    .span3 {
      grid-column: span 3
    }
    .span2 {
      grid-column: span 2
    }
  }
  .form-row.overloaded {
    grid-template-columns: repeat(auto-fit, minmax(125px, 100%));
  }
  .box-button {
    padding-top: 0;
    grid-template-columns: repeat(auto-fit, minmax(125px, .3fr));
  }
}