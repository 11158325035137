@import "../global";

// Variables
@import "variables";

// Coreui
@import "~@coreui/coreui/scss/coreui";
@import "~@coreui/icons/css/free.min.css";

// Bootstrap
@import "~bootstrap/scss/bootstrap";
@import "~glyphicons-only-bootstrap/css/bootstrap.min.css";

// Bootstrap FileInput
@import "~bootstrap-fileinput/css/fileinput.css";

// Bootstrap Icons
@import "~bootstrap-icons/font/bootstrap-icons.css";

// Select2
@import "~select2-bootstrap4-theme/dist/select2-bootstrap4.min.css";
@import "~select2/dist/css/select2.css";
@import "../includes/select2-custom";

@import "~@splidejs/splide/dist/css/splide.min.css";
@import "~ekko-lightbox/dist/ekko-lightbox.css";
@import "~croppr/dist/croppr.min.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "~datatables/media/css/jquery.dataTables.min.css";
@import "~gridstack/dist/gridstack.min.css";
@import "~cropperjs/dist/cropper.min.css";

// easy-markdown-editor
@import '~easymde/dist/easymde.min.css';

@import "./file.scss";

@import "./uploadimg.scss";

@import "./video.scss";

@import "./kroger.scss";

@import "./pilot.scss";

@import "./sam.scss";

@import "./group-preview.scss";
@import "./group-show.scss";

@import "./new_template.scss";

@import "./cropper.css";
@import "./vue-good-table.css";

@import "./share_Banner.scss";

@import "./nft.scss";

@import "./auth.scss";

@import "./input.scss";

@import "./button.scss";

@import "./sidebar.scss";

@import "./header.scss";

@import "./layout-page.scss";

@import "./projects-page.scss";

@import "./section-card.scss";

@import "./banner.scss";

@import "./file-image.scss";

@import "./modal/select-image";
@import "./modal/bulk-update";
@import "./modal/settings_modal";

@import "./gallery.scss";
@import '~jquery-ui/themes/base/all.css';

@import "keyword-suggestion-select";

@import "./modal/publish-modal";

@import "./job-progress-bar";

.slide-item {
  img {
    height: 100px;
    max-width: 90%;
    min-width: 100px;
    margin: 0 auto;
    object-fit: scale-down;
    cursor: pointer;
    padding: 0 5px;

    &:hover {
      background: rgba(0, 117, 255, 0.15);
      border-radius: 3px;
    }

    &.selected {
      -moz-box-shadow: inset 0 0 2px rgba(201, 195, 240, 0.2);
      -webkit-box-shadow: inset 0 0 2px rgba(201, 195, 240, 0.2);
      box-shadow: inset 0 0 2px rgba(201, 195, 240, 0.2);
      // border: 2px solid #209C86;
      border-radius: 3px;
    }
  }

  p {
    line-height: 100px;
    margin: 0;
    text-align: center;
    font-size: 18px;
    user-select: none;
    cursor: pointer;

    &:hover {
      background: rgba(0, 117, 255, 0.15);
      border-radius: 3px;
    }

    &.selected {
      -moz-box-shadow: inset 0 0 2px rgba(201, 195, 240, 0.2);
      -webkit-box-shadow: inset 0 0 2px rgba(201, 195, 240, 0.2);
      box-shadow: inset 0 0 2px rgba(201, 195, 240, 0.2);
      border: 2px solid #209C86;
      border-radius: 3px;
    }
  }
}

.selected-customer {
  display: inline-block;
  cursor: pointer;

  .slide-item {
    img {
      max-width: 100px;
    }
  }
}

.customer-wrapper {
  display: inline-block;
  cursor: pointer;
}

.selected-template {
  .slide-item {
    img {
      max-width: 300px;
    }
  }
}

.template-wrapper {
  display: inline-block;
  // margin-left: 30px;

  .templates {
    width: calc(100% - 130px);
  }
}

.slide-popup {
  background: white;
  padding: 30px 45px;
  border: 1px solid #ccc;
  outline: 0;
  margin-top: 15px;
  width: 100%;
  position: absolute;
  top: 20px;
  left: 50%;
  z-index: 1;
  display: none;
  transform: translateX(-28px);

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -8px;
    left: 28px;
    border: 8px solid;
    border-color: transparent transparent white white;
    box-shadow: -1px 1px 1px #ccc;
    transform: translateX(-50%) rotate(135deg);
  }

  .slick-prev:before,
  .slick-next:before {
    color: grey;
  }
}

.slide-popup::before {
  content: "";
  display: block;
  position: absolute;
  top: -8px;
  left: 28px;
  /* top: 188px; */
  border: 8px solid;
  border-color: transparent transparent white white;
  /* border-color: transparent transparent var(--nav-dropdown-bg) var(--nav-dropdown-bg); */
  box-shadow: -1px 1px 1px #ccc;
  transform: translateX(-50%) rotate(135deg);
}

form#files-search-form {
  position: relative;
  margin-bottom: 20px;

  ul.suggest {
    display: none;
    position: absolute;
    top: 30px;
    left: 20px;
    border: 1px solid gray;
    border-radius: 5px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    padding: 0;
    background-color: rgb(204, 55, 55);

    li {
      list-style-type: none;
      cursor: pointer;
      padding: 0 10px;

      &:hover {
        background-color: blue;
        color: white;
      }
    }
  }
}

.selected-block {
  display: none;
}

table#selected-files {
  margin-bottom: 20px;

  tr > th,
  td {
    padding: 2px 10px;
  }

  span.remove {
    cursor: pointer;
    transition: all 0.5s;

    &:hover {
      color: red;
    }
  }
}

table#files-index-table {
  margin-bottom: 20px;

  tr > th,
  td {
    padding: 2px 10px;
  }
}

div.index-info {
  position: fixed;
  z-index: 1000;
  top: 20px;
  right: 20px;
  padding: 20px;
  background-color: blue;
  color: white;
  border-radius: 5px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  max-width: 300px;

  p {
    margin: 0;
  }
}

div.download-block {
  display: none;
  float: right;
  margin-top: 20px;

  p {
    margin: 0;
  }
}

#files-index-table_wrapper {
  .dataTables_filter {
    display: none;
  }
}

.search-block {
  float: right;

  #index-search-input {
    min-width: 500px;
  }
}

label[for="global-download"] {
  margin: 0;
}

button#global-download-unselect {
  outline: none;
  cursor: pointer;

  span {
    font-size: 1rem;
  }
}

.center-checkbox {
  display: flex;
  align-items: center;
}

.col-upc-gtin {
  flex: 1;
}

.col-product-spacing {
  width: 150px;
}

input[type=file],
/* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer !important;
}

.two-color-picker {
  position: relative;

  .background-color {
    position: absolute;
    display: inline-block;
    width: 40px;
    height: 25px;
    right: 25px;
    top: 5px;
    border-radius: 2px;
    overflow: hidden;

    .circle-color {
      display: inline-block;
      width: 25px;
      height: 25px;
      border-radius: 20px;
      margin-top: -12px;
      margin-left: 20px;
      position: absolute;
    }
  }

  .background-color.blue {
    background-color: #b8dde1;

    .circle-color {
      background-color: #05a4b4;
    }
  }

  .background-color.teal {
    background-color: #d2f7e7;

    .circle-color {
      background-color: #36c2b4;
    }
  }

  .background-color.green {
    background-color: #e4fdbf;

    .circle-color {
      background-color: #ade421;
    }
  }

  .background-color.yellow {
    background-color: #ffebb7;

    .circle-color {
      background-color: #f3aa46;
    }
  }

  .background-color.red {
    background-color: #fcceaa;

    .circle-color {
      background-color: #ff7676;
    }
  }

  .background-color.pink {
    background-color: #ffcfcf;

    .circle-color {
      background-color: #ff7676;
    }
  }

  .background-color.purple {
    background-color: #ffd1f0;

    .circle-color {
      background-color: #dd85d1;
    }
  }
}

.generate-alert {
  display: none;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
}

.ekko-lightbox-nav-overlay {
  a {
    color: #333 !important;
  }
}

div.product_custom_layering {
  display: inline-block;
}

/* ----------- grid-item --------------- */
.image-grid-responsive {
  overflow: hidden;
  padding: 20px 0 30px;
  min-height: 350px;
}

.grid-item {
  width: 200px;
  height: 150px;
  float: left;
  border: 1px solid transparent;
  user-select: none;
  position: relative;

  .select-check {
    position: absolute;
    left: 0;
    opacity: 0;
  }

  img {
    height: calc(100% - 35px);
    max-width: 100%;
    object-fit: contain;
    display: block;
    margin: 0 auto;
  }

  .item-filename {
    word-wrap: break-word;
    line-height: 1;
    text-align: center;
    width: 100%;
    display: block;
  }

  p {
    margin: 0;
    text-align: center;
  }

  .budge {
    color: white;
    font-size: 12px;
    background: red;
    padding: 0 3px;
    border-radius: 5px;
    position: absolute;
  }

  &:hover,
  &.selected {
    .select-check {
      opacity: 1;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 35px;
    display: flex;
    align-items: center;
    border-radius: 5px;

    a {
      margin: 0 auto;
      padding: 0 .75rem;
      color: white;
      border-radius: 10px;
      background-color: #000015b3;
    }
  }

  &.image-none {
    .grey-background {
      width: 100px;
      height: 100%;
      margin: 0 auto;
      background-color: #888888;
    }
  }
}

.available-image-grid {
  .image-grid-responsive {
    min-height: auto;
  }
}

.ekko-lightbox {
  .modal-header {
    display: none;

    .close {
      padding: 0;
      width: 30px;
      height: 30px;
      border: 3px solid black;
      border-radius: 50%;
      background: white;
      outline: none;
      z-index: 100;
    }
  }
}

#draft-section table tr td:not(:last-child),
#project-section table tr td:not(:last-child) {
  cursor: pointer;
}

#preview-popup {
  // box-shadow: 2px 2px #888888;
  background: white;
  // position: fixed;
  // top: 70px;
  // right: 5px;
  // z-index: 1031;
  display: none;

  #drag-handler {
    width: 100%;
    padding: 5px;
    // border: 1px solid;
    // cursor: move;
  }

  .canvas-container {
    // border: 1px solid;
    box-sizing: content-box;
  }

  #footer {
    border: 1px solid;
    box-sizing: content-box;

    label {
      font-size: 10px;
    }

    input {
      font-size: 10px;
      -moz-appearance: textfield;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .preview-control {
    float: right;
    font-size: 15px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    > button {
      width: 100%;
      height: 100%;
      border: 0;
      background: none;
      padding: 0;
      border-radius: inherit;
      appearance: none;
      font: inherit;
      transition: background-color 0.35s;

      &:hover {
        background: lightgrey;
      }
    }

    &:hover {
      background: lightgrey;
    }
  }

  .preview-control .dropdown-menu {
    padding: 0.25rem 0;

    button {
      width: 100%;
      text-align: left;
      padding: 0.125rem 0.5rem;
    }

    .dropdown-submenu {
      position: relative;

      &:after {
        content: "\F282";
        font-family: bootstrap-icons, serif !important;
        position: absolute;
        right: 0.5rem;
      }

      &:hover {
        &:after {
          transition: transform 0.1s ease-in;
          transform: rotate(180deg);
        }

        .dropdown-menu {
          display: block;
        }
      }

      .dropdown-menu {
        position: absolute;
        top: 0;
        transform: translate(-100%);
        margin: 0;
      }
    }
  }
}

.custom-control-label {
  cursor: pointer;
}

.text-label {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.column-handler {
  cursor: pointer;
}

.dropdown-menu {
  z-index: 1032 !important;
}

#adForm select {
  cursor: pointer;
}

.toggle-shape {
  position: absolute;
  top: 12px;
  right: 25%;
}
.clr-field {
  width: 100%;
}

.notification {
  padding: 5px;
  background: #a4ffa4;
  border: #00ff00;
}

.c-header.c-header-fixed {
  top: 0;
}

.click-file {
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.template-table-wrapper {
  height: calc(100vh - 305px);
}

// video preview
tr.droppable {
  z-index: 1;
}

.droppable.active {
  th,
  td {
    background-color: #ccc;
  }
}

// #preview {
//     background: #000;
// }

.filename {
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding: 0.75rem 0.75rem 0.25rem 0.75rem !important;
  min-height: 61px;
  min-width: 265px !important;
}

.text-ellipsis-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.ui-sortable-helper td.filename {
  word-break: unset;
  overflow: hidden;
  display: none;
  -webkit-line-clamp: unset;
  -webkit-box-orient: unset;
  padding: 0.75rem !important;
  min-height: unset;
  max-width: 100px !important;
  float: left;
}

table.vgt-table {
  thead {
    position: sticky;
    top: 0;
    z-index: 10;
    box-shadow: 0 3px 2px -1px grey;
  }

  th.vgt-row-header {
    transition: background-color 0.5s, border-color 0.5s;
    transition-timing-function: ease-in-out;
    background: lightgrey;
  }

  td {
    background: white;
  }

  th:first-child,
  td:first-child {
    position: sticky;
    z-index: 5;
    left: 1px;
    min-width: 50px;
    border-left: solid 1px #dcdfe6;
  }

  .dropdown-menu {
    margin-left: 5px !important;
  }

  .line-numbers {
    cursor: move;
  }
}

table.sticky-column-1 {
  th:nth-child(3),
  td:nth-child(3) {
    position: sticky;
    z-index: 5;
    left: 51px
  }
}

.vgt-table.bordered th.vgt-row-header:nth-child(2) {
  cursor: pointer;
}

.vgt-responsive {
  max-height: 600px;
  min-height: 470px;
}

.ms-choice {
  border: none;
  outline: none;
  height: 100%;
  line-height: 2.4;
}

.isDisabled {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}
.isDisabled > a {
  color: currentColor;
  display: inline-block;  /* For IE11/ MS Edge bug */
  pointer-events: none;
  text-decoration: none;
}

tbody.vgt-table-delimiter > .vgt-group-row th.vgt-row-header {
  border-top-color: #000;
}

.unstyled-btn {
  margin: 0;
  padding: 0;
  border: 0;
  appearance: none;
  background: none;
  font: inherit;
  border-radius: inherit;
}

.flip-settings-button {
  display: none;
}

.dropdown-menu.rotate-shadow.show {
  min-width: 12rem;
}
a[disabled] {
  opacity: 0.5;
  pointer-events: none;
}

.splide-btn-prev {
  left: -2.3rem;
  transition: 0.3s;

  &:hover {
    background: $bg-hover;
    transition: 0.4s;
  }
}

.splide-btn-next {
  right: -2.3rem;
  transition: 0.3s;
  
  &:hover {
    background: $bg-hover;
    transition: 0.3s;
  }
}

.splide-pagination-wrapper {
  bottom: -1.5rem;
}

.splide_pagination_dot {
  height: 10px;
  width: 10px;
  
  &.is-active {
    background: $bg-hover;
  }
}

//Style for .dropdown-btn-link delete item in uploadimg
.dropdown-btn-link{
  border: none;
  background: none;
  padding: 0;
  width: 100%;
  text-align: left;
}

div.card.card-upload-img {
    .card-body {
        table tbody tr td{
            max-width: 23em;
        }
    }
}

#layers-modal {
    z-index: 1031;
    position: fixed;
    top: 56px;
    left: 5px;
    background: white;
    width: 600px;
    border: 0.5625px solid #b1b1b1;
    border-radius: 6px;
    box-shadow: rgb(208 208 208 / 45%) 0px 4px 20px;
    
    #layers-order-control-panel {
        label {
            text-overflow: unset;
            overflow: visible;
            white-space: normal;
            max-height: none;
            max-width: none;
            line-height: unset;
        }
    }
    #draggable-header {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        background-color: #e1e1e1;
        cursor: move;
        z-index: 1031;
        width: 100%;
        padding: 5px;
    }
    .layers-body {
        width: 100%;
    }
    .table-container {
        max-height: 420px;
        overflow-y: auto;
        table {
            text-align: left;
            position: relative;
            border-collapse: collapse;
        }
        th, td {
            padding: 0.25rem;
            background: #f6f6f6;
            /* font-weight: bolder; */
            color: gray;
        }
        
        th {
            background: white;
            position: sticky;
            top: 0;
            box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
            cursor: pointer;
            
            span {
                pointer-events: none;
            }
        }
        td {
          &.with-canvas-object {
            background: #007964;
            font-weight: bolder;
            color: white;
            border:none;
          }
        }
        td {
          &.selected_layer {
            background-color: #0f5e53 !important;
          }
        }
        
    }
    .layers-footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        align-content: center;
        padding: 5px;
        button {
            margin-left: 5px;
        }
    }
    .form-control {
        line-height: 1.5;
        border-radius: 0.2rem !important;
        height: calc(1.5em + 0.5rem + 2px);
    }
}

.overflow-visible {
  overflow: visible !important;
}

#createModal {
  .form-check-label {
    font-weight: 600;
    color: #727272;
    margin-left: 4px;
    font-size: 14px;
  }
  
  .form-check-input {
    margin-top: 6px;
  }
  .modal-body {
    .vtt-select-wrapper {
      margin-left: 17px;
      
      .vtt-select {
        width: 145px;
      }
    }
    
    select {
      color: #727272;
      border-radius: 15px!important;
    }
    
    .audio-codec-wr {
      display: flex;
      align-items: center;
      margin-left: 20px;
      
      .codec-select-wr {
        margin-left: 17px;
        
        .codec-select {
          width: 85px;
        }
      }
    }
  }
  
  .modal-footer {
    select {
      color: #727272;
      border-radius: 15px!important;
    }
    
    button {
      background-color: #209C86;
      border-radius: 6px;
      border-color: transparent;
    }
    
    .btn-secondary {
      background-color: #6c757d;
    }
  }
}

.w-8 {
  width: 8%;
}

.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.relative {
  position: relative !important;
}

.swal2-radio {
    display: inline-flex !important;
    flex-direction: column;
    align-items: flex-start !important;
    background: inherit !important;
}

.drawpad-colorbox {
  box-shadow: 0 0 7px 1px;
}

.icon-eraser {
  background-image: url('/img/icons/eraser.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: inline-block;
  width: 1rem;
  height: 1rem;
}

.pcr-result {
  min-width: 100% !important;
}

.pcr-button::after {
  border: 1px solid black;
}

.edit-container-sidebar {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.full-size-image {
  .full-size-image-dist {
    .container-sidebar {
      
      .fade-tool-opacity-container {
        top: 0;
      }

      .edit-fade-tool-length-container, 
      .edit-fade-tool-width-container {
        flex-direction: column;
        height: 40px;
        margin-right: 5px;
      }

      .edit-fade-tool-length,
      .edit-fade-tool-width {
        height: auto;
        padding: 0;
      }
    }
  }
} 

.fade_range_block{
  display: flex;
}

#product_description_block button.btn-primary,
#generateManyProductDescriptionsModal button.btn-primary {
  color: white;
  background-color: #209c86 !important;
  border-color: #209c86;
}

.custom-prompts-dropdown-menu {
  --initial-width: max-content;
  width: var(--initial-width);
  max-height: 320px;
  overflow-y: auto;
}

.product-desc-tab-block {
  border-radius: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.loadingOverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;

  .loadingOverlay-inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      text-align: center;
      color: white;
      .fa {
          font-size: 40px;
          margin-bottom: 20px;
      }
  }
}

.login_page,
.login_page:focus,
.login_page:hover,
.login_page:active {
  color: #000000;
  text-decoration: underline;
}

.loginText {
  text-align: center;
  font-size: 24px;
}

#loginText {
  margin: 0 0 10px 0;
  padding-right: 80px;
}

.sharedLogo {
  width: auto;
  height: 30px;
  margin-top: 5px;
}

.inline-tooltip {
  .tooltip-inner {
    white-space:nowrap;
    max-width:none!important;
    padding-right: 10px;
  }
}

.homebtn {
  background: none;
  border: none;
  outline: none;
}

.home svg {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.loginBlock {
  min-height: 90px;
}

.logo-proof {
  width: 139px;
  margin-right: 20px;
}

.login-proof {
  width: 100%;
  font-size: 14px;
}

#previewFile .img-fluid {
  max-height: 75vh;
}

a.card, a.card:hover  {
  text-decoration: none;
  color: inherit;
}

.ui-draggable-handle {
  cursor: move;
}

.cke_editable {
  font-family: sans-serif, Arial, Verdana, "Trebuchet MS", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.ui-datepicker {
  width: 20em;
}

.ui-datepicker-calendar thead tr th {
  font-size: 12px !important;
}

.ui-datepicker-calendar td a {
  text-align: center;
  background-color: unset !important;
  border: none !important;
}

.ui-datepicker-calendar td {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

.ui-state-highlight, .ui-widget-content .ui-state-highlight {
  border-bottom: 2px solid #0052CC !important;
  color: #0052CC;
  font-weight: bold;
}

.ui-state-default.ui-state-active {
  background-color: #42526e !important;
  border-radius: 5px;
}

.ui-widget-header, .ui-datepicker-next-hover, .ui-datepicker-prev-hover {
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  top: 2px !important;
}

.ui-datepicker-next-hover {
  right: 2px !important;
}

.ui-datepicker-prev-hover {
  left: 2px !important;
}

.cke_dialog_container {
  width: 500px!important;
  left: 35%!important;
}

.cke_dialog_ui_hbox_last{
  text-align: right!important;
  padding-right: 10px!important;
}

#dropZone {
  border: 2px dashed #ccc;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  font-size: 1.2em;
  cursor: pointer;
  height: 100%;
}
.filePreview {
  border: 1px solid #ccc;
  border-radius: 10px;
  position: relative;
  height: 100%;
}

.filePreview .removeButton {
  font-size: 1em;
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 1px;
}

.filePreview #dropZone {
  padding: 20px;
  text-align: center;
  font-size: 1.2em;
  cursor: pointer;
}

.filePreview #dropZone.dragover {
  background-color: #f7f7f7;
}

.filePreview .previewArea img {
  max-width: 120px;
  max-height: 120px;
  margin: 10px;
}

.filePreviewBlock {
  position: relative;
  padding-right: 15px;
}

.remove_file {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0;
  margin: 0 !important;
}

.new_project_columns {
  cursor: all-scroll;
}

.item.dragging {
  opacity: 0.6;
}

.cke_widget_drag_handler_container {
  top: 0 !important;
}

.back-to-folder-link {
  cursor: pointer;
  margin: 10px;
  width: fit-content;

  p {
    font-weight: bold;
  }
}

.modal-title {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.indicators-container {
  display: block;
  position: absolute;
  top: 10px;
  right: 32px;
  z-index: 1;
}
.indicator {
  color: #b1b1b1;
}

.indicator {
  display: flex;
  align-items: center;
  margin-right: 20px;

  .slider {
    cursor: pointer;
    position: absolute;
    top: 3px;
    height: 17px;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    right: -12px;

    &:before {
      position: absolute;
      content: "";
      height: 13px;
      width: 13px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }
  }

  .active.slider {
    background-color: #209c86;

    &:before {
      -webkit-transform: translateX(13px);
      -ms-transform: translateX(13px);
      transform: translateX(13px);
    }
  }
}

hr[data-content] {
  text-align: center;

  &:after {
    background: #fff;
    color: rgba(0, 0, 0, 0.2);
    content: attr(data-content);
    padding: 0 4px;
    position: relative;
    top: -13px;
  }
}

#feed_progress {
  .progress {
    position: relative;
    .progress-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: bold;
      color: #135d4f;
      z-index: 2;
    }
  }
}

