/*
 * bootstrap-tagsinput v0.8.0
 * 
 */

 .bootstrap-tagsinput {
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    display: inline-block;
    padding: 4px 6px;
    color: #555;
    vertical-align: middle;
    border-radius: 4px;
    max-width: 100%;
    line-height: 22px;
    cursor: text;
  }
  .bootstrap-tagsinput input {
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    padding: 0 6px;
    margin: 0;
    width: 100%;
    max-width: inherit;
  }
  .bootstrap-tagsinput.form-control input::-moz-placeholder {
    color: #777;
    opacity: 1;
  }
  .bootstrap-tagsinput.form-control input:-ms-input-placeholder {
    color: #777;
  }
  .bootstrap-tagsinput.form-control input::-webkit-input-placeholder {
    color: #777;
  }
  .bootstrap-tagsinput input:focus {
    border: none;
    box-shadow: none;
  }
  .bootstrap-tagsinput .tag {
    margin-right: 2px;
    color: white;
  }
  .bootstrap-tagsinput .tag [data-role="remove"] {
    margin-left: 8px;
    cursor: pointer;
  }
  .bootstrap-tagsinput .tag [data-role="remove"]:after {
    content: "x";
    padding: 0px 2px;
  }
  .bootstrap-tagsinput .tag [data-role="remove"]:hover {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  }
  .bootstrap-tagsinput .tag [data-role="remove"]:hover:active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }

  .input-group {
  /* margin-top: 30px; */
  position: relative;
}

.input-group {
  position: relative;
}

.input-group-addon {
  border: none;
}

.linkname {
  display: none;
}

#copyButton {
    cursor: pointer;
    background-color: #f4f4f4;
    border: 1px solid #ddd5d5;
    border-left: none;
}

#copyTarget{
    z-index: 100;
}

.copied {
  opacity: 1;
  position: absolute;
  left: 95px;
  z-index: 0;
}

@media (min-width: 768px) {
  .copied {
    left: 95px;
  }

  .linkname {
    display: block;
    background: #3b3e45;
    color: #fff;
  }
}

.bootstrap-tagsinput {
    width: 100%;
}

.bootstrap-tagsinput .tag:not(.badge) {
    background-color: #dcedff;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    display: inline-block;
    padding: 4px 7px;
    color: #003f82 !important;
    vertical-align: middle;
    border-radius: 4px;
    max-width: 100%;
    line-height: 23px;
    cursor: text;
    font-weight: 500;
}

.height-none{
  height: 32px;
}
.height-display{
  height: auto !important;
}

/* custom */

.input-group {
    /* margin-top: 30px; */
    position: relative;
  }
  
  .input-group {
    position: relative;
  }
  
  .input-group-addon {
    border: none;
  }
  
  .linkname {
    display: none;
  }
  
  #copyButton {
      cursor: pointer;
      background-color: #f4f4f4;
      border: 1px solid #ddd5d5;
      border-left: none;
  }
  
  #copyTarget{
      z-index: 100;
  }
  
  .copied {
    opacity: 1;
    position: absolute;
    left: 95px;
    z-index: 0;
  }
  
  @media (min-width: 768px) {
    .copied {
      left: 95px;
    }
  
    .linkname {
      display: block;
      background: #3b3e45;
      color: #fff;
    }
  }
  
  .bootstrap-tagsinput {
      width: 100%;
  }
  
  .bootstrap-tagsinput .tag:not(.badge) {
      background-color: #dcedff;
      box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
      display: inline-block;
      padding: 4px 7px;
      color: #003f82 !important;
      vertical-align: middle;
      border-radius: 4px;
      max-width: 100%;
      line-height: 23px;
      cursor: text;
      font-weight: 500;
  }
  
  .height-none{
    height: 32px;
  }
  .height-display{
    height: auto !important;
  }
