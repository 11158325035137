@import "variables";

.tab-pane.fade.active.show.project-table{
  position: relative;

  .bi.bi-search {
    position: absolute;
    z-index: 9;
    top: 37px;
    left: 260px;
    color: $greyscale300;
  }
}

// table styles
.projects-table-wrapper {
  // select & input
  .row.mb-4 {
    font-family: $font-family-sans-serif;
    color: $greyscale400;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    flex-direction: row;
    margin-top: 20px;

    @media (max-width: $breakpoint-sm) {
      flex-direction: column;
    }

    .form-control {
      height: calc(2.5em + 0.75rem + 2px);
      border: 1px solid $greyscale100;
      border-radius: 16px;

      &:focus {
        box-shadow: none;
        border: 1px solid $greyscale200;
      }
    }

    .col.form-inline {
      display: none;
      select {
        height: calc(2.8em + 0.8rem + 2px);
        color: $greyscale400;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;

        @media (max-width: $breakpoint-sm) {
          margin: 10px 0 20px 0;
        }
      }
    }

    .col {
      max-width: 320px!important;
      min-width: 320px!important;

      .form-control {
        padding: 0 40px 0 15px!important;
      }
    }
  }
  // table
  .table-responsive.custom {
    font-family: $font-family-sans-serif;
    border: $greyscale200 1px solid;
    border-radius: 16px;

    .table.table-borderless.custom {
      background-color: white;

      // head
      .table-head-custom {
        color: $primary300;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;

        tr > th {
          text-align: left;

          span > i {
            &::before {
              color: $greyscale300;
              font-size: 12px;
            }
          }
        }

        tr > th {
          line-height: 25px;
          padding-left: 10px;
        }
      }

      //body
      tbody {
        color: $greyscale400;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;

        tr > td:nth-child(1) {
          width: 5%!important;
        }

        tr > td {
          text-align: left;
          line-height: 25px;
          
          .badge-success {
            padding: 0.25em 0.4em;
          }

          div > {
            span {
              color: white!important;
              font-size: 16px!important;
              font-style: normal!important;
              font-weight: 500!important;
            }
          }

          span {
            color: $greyscale400!important;
            font-style: normal!important;
            font-weight: 500!important;
            font-size: 16px!important;
            padding: 0.25em 0;
          }
        }
      }
    }
  }

  // pagination
  .pagination {
    justify-content: center;
    margin: 0;

    .page-link {
      margin: 0 6px 0 6px;
      min-width: 38px;
      border-radius: 10px!important;
      border-color: transparent!important;
      text-align: left;
      font-weight: bold;
      white-space: nowrap;

      &:focus {
        box-shadow: none!important;
      }

      @media (max-width: $breakpoint-md) {
        width: 100%;
      }
    }

    .page-item.disabled {
      .page-link {
        background-color: $greyscale50;
        color: $greyscale200;
      }
    }

    .page-item.active.d-none.d-md-block {
      .page-link {
        background-color: $secondary;
        cursor: pointer;
        color: white;
        transition: 0.2s;

        &:hover {
          transition: 0.2s;
          background-color: darken($secondary, 5%);
        }
      }
    }

    .page-item.d-none.d-md-block {
      .page-link {
        background-color: $greyscale50;
        color: $primary700;

        transition: 0.2s;

        &:hover {
          transition: 0.2s;
          background-color: darken($greyscale50, 5%);
        }
      }
    }

    .page-item {
      .page-link {
        background-color: $greyscale50;
        color: $primary700;

        transition: 0.2s;

        &:hover {
          transition: 0.2s;
          background-color: darken($greyscale50, 5%);
        }
      }
    }
  }

  .col.text-right.text-muted {
    display: none;
  }
}

// projects & video table actions
.projects-table-management {
  justify-content: flex-end;

  font-family: $font-family-sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  color: $greyscale400;
  text-decoration: none!important;
  line-height: 19px;

  .management-link {
    font-size: 16px!important;
  }

  #download_all {
    padding: 0!important;
    margin: 0 10px 0 0!important;

    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    color: $greyscale400;
    text-decoration: none!important;
    transition: 0.2s;

    &:hover {
      transition: 0.2s;
      color: $greyscale300;
    }
  }
}

// dropdown
.dropdown.project-actions {
  display: flex;
  justify-content: flex-start;

  .action {
    display: inline-block;
    width: 50px;

    .ellipsis-vertical {
      svg {
        width: 20px;
        height: 20px;
        path {
          transition: 0.2s;
          fill: $greyscale400;
        }
      }

      &:hover {
        svg {
          path {
            transition: 0.2s;
            fill: $secondary;
          }
        }
      }
    }
  }

  .btn.dropdown-item.action-menu {
    display: flex;
    line-height: 35px;
    color: $greyscale400;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;

    i {
      margin: 0 13px 18px 0;
    }

    .download-icon {
      margin: 0 10px 2px 0;

      svg {
        width: 18px;
        height: 18px;

        path {
          transition: 0.2s;
          fill: $greyscale400;
        }
      }
    }

    .pencil-alt {
      margin: 0 10px 2px 0;

      svg {
        transition: 0.2s;
        width: 18px;
        height: 19px;
        stroke: $greyscale400;
      }
    }

    .document-duplicate {
      margin: 0 10px 2px 0;

      svg {
        width: 20px;
        height: 20px;

        path {
          transition: 0.2s;
          fill: $greyscale400;
        }
      }
    }

    .trash {
      margin: 0 10px 2px 0;

      svg {
        width: 20px;
        height: 20px;

        path {
          transition: 0.2s;
          fill: $greyscale400;
        }
      }
    }
  }
}

.dropdown.project-actions.show {
  .ellipsis-vertical {
    svg {
      width: 20px;
      height: 20px;

      path {
        fill: $secondary;
      }
    }
  }

  .dropdown-menu.show {
    .dropdown-item {
      transition: 0.2s;

      &:hover {
        transition: 0.2s;
        color: $secondary;
        background-color: white;

        .download-icon {
          margin: 0 10px 2px 0;

          svg {
            width: 18px;
            height: 18px;

            path {
              transition: 0.2s;
              fill: $secondary;
            }
          }
        }

        .pencil-alt {
          margin: 0 10px 2px 0;

          svg {
            transition: 0.2s;
            width: 18px;
            height: 19px;
            stroke: $secondary;
          }
        }

        .document-duplicate {
          margin: 0 10px 2px 0;

          svg {
            width: 20px;
            height: 20px;

            path {
              transition: 0.2s;
              fill: $secondary;
            }
          }
        }

        .trash {
          margin: 0 10px 2px 0;

          svg {
            width: 20px;
            height: 20px;

            path {
              transition: 0.2s;
              fill: $secondary;
            }
          }
        }
      }
    }
  }
}

.dropdown.project-actions.grid-view {
  position: absolute!important;
  top: 0;
  right: 0;
}

#grid_elements {
  margin-top: 18px;
}

// projects & video table actions
.projects-table-management {
  justify-content: flex-end;
  
  font-family: $font-family-sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  color: $greyscale400;
  text-decoration: none!important;
  line-height: 19px;
  
  .management-link {
    font-size: 16px!important;
  }
  
  #download_all {
    padding: 0!important;
    margin: 0 10px 0 0!important;

    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    color: $greyscale400;
    text-decoration: none!important;
    transition: 0.2s;

    &:hover {
      transition: 0.2s;
      color: $greyscale300;
    }
  }
}
