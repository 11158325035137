.file-operations {
    user-select: none;
    display: inline-block;
    cursor: pointer;

    ul {
        display: none;
        margin: 5px 0 0 -5px;
        padding: 0;
        list-style: none;
        position: absolute;
        background: white;
        border: 1px solid #cccccc;
        z-index: 100;
        box-shadow: 1px 1px #888888;

        li {
            padding: 0 5px;
            cursor: pointer;
            user-select: none;

            &:hover {
                background: #d3d3d3;
            }
        }
    }
}


#productViewModal {
	.modal-body {
		a {
			user-select: none;
		}
		.product-info {
			flex: 1;

			table {
				th {
					width: 170px;
				}
				td {
					input {
						width: 100%;
					}
				}
			}
		}
		.product-image {
			flex: 1;
			display: flex;
			padding-left: 0.5rem;
			height: 512px;

			.thumbnail-images {
				width: 150px;

				ul {
					padding: 0;
					list-style: none;
					text-align: center;

					li {
						margin: 10px auto;
						width: 82px;
						height: 82px;
						position: relative;

						&:hover, &.selected {
							border: 1px solid #888888;
						}

						img {
							position: absolute;
							left: 50%;
							top: 50%;
							transform: translate(-50%, -50%);
							max-width: 80px;
							max-height: 80px;
							height: auto;
						}
					}
				}
			}
			.image-view {
				align-items: center;
				display: flex;
				width: calc(100% - 150px);
				padding: 0 10px;

				#full-size-image {
					margin: 0 auto;
					max-height: 100%;
					max-width: 100%;
					align-items: center;

					&:hover {
						cursor: zoom-in;
					}
				}
				.croppr-container {
					margin: 0 auto;
				}
			}
		}

		.single-image {
			text-align: center;

			img {
				height: 512px;
				max-width: 100%;
				object-fit: contain;
			}
		}
	}
}

.image-grid-pagination {
	text-align: right;
}

.full-size-image {
	text-align: center;
  .full-size-image-menu {
    a {

      margin: 0 5px;
    }
  }
	.btn-back-grid {
		display: block;
		text-align: left;
	}
	img {
		display: block;
		margin: 0 auto;
		max-height: 400px;
	}

  .full-size-image-dist{
    .container-sidebar {
      position: relative;
      padding-bottom: 5px;
	  .fade-tool-opacity-container {
	  	display: inline-flex;
		flex-direction: column;
		height: 40px;
		position: relative;
		top: -8px;
		label {
			height: 20px;
			text-align: center;
			margin-bottom: 0;
		}
		input {
			height: 20px;
		}
	  }
      .fade-tool-position{
        width: 100px;
      }
      .fade-tool-gradient {
        width: 40px;
        top: 5px;
        padding: 3px;
        position: inherit;
      }
	  .fade-tool-length-container, .fade-tool-width-container {
		display: inline-flex;
		flex-direction: row;
		align-items: center;
		label {
			margin: 0 4px 0 0 !important;
		}
	  }
      .fade-tool-length, .fade-tool-width {
        width: 80px;
      }
    }
  }

}

.paginationjs-pages ul {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		box-sizing: border-box;
		display: inline-block;
		min-width: 1.5em;
		padding: 0.5em 1em;
		margin-left: 2px;
		text-align: center;
		cursor: pointer;
		border: 1px solid transparent;
		border-radius: 2px;

		a {
			color: #333;
			text-decoration: none;
		}

		&.disabled {
			user-select: none;

			&:hover {
				background: none;
				border: 1px solid transparent;

				a {
					color: #333;
				}
			}
		}

		&:hover {
			border: 1px solid #111;
			background-color: #585858;
			background: linear-gradient(to bottom, #585858 0%, #111 100%);

			a {
				color: white;
			}
		}

		&.active {
			color: #333;
			border: 1px solid #979797;
			background-color: white;
			background: linear-gradient(to bottom, #fff 0%, #dcdcdc 100%);
		}
	}
}

.image-edit-tools {
	margin-top: 10px;

	.image-edit-button {
		width: 25px;
		height: 25px;
		display: inline-block;
		text-align: center;
		line-height: 25px;
		margin-right: 10px;
		text-decoration: none;
		color: black;
		background: #d3d3d3;
		border: 1px solid #EEE;

		&:hover {
			background: #aaaaaa;
		}
	}

	.button-group {
		display: none;
	}
}

.crop-overlay {
	position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    background: #0000007f;
    color: white;
    display: none;
    justify-content: center;
	align-items: center;
	z-index: 1051;
}
